import { IVessel_List__Get, IVesselStore, IVessel } from "./types";
import { jobSelectors } from "../../_core/job";
import actionTypes from "./action-types";
import { IJobGetStatus } from "../../_core/job/types";
import { IListObjectStore } from "../../_common/type";
import { stat } from "fs";
import { IStore } from "../../store";

const {
	VESSEL_LIST_LOAD,
	VESSEL_LIST_LOAD_NEW,
	VESSEL_LIST_FILTER,
	FLEET_SELECTED_VESSEL_ASSIGN,
	FLEET_CREATE,
	MY_VESSELS_LOAD,
} = actionTypes;

function getVesselFormStaticData() {
	return {
		imoNumber: {
			label: "IMO number",
			placeholder: "",
		},
		vesselPrefix: {
			label: "Vessel prefix",
			placeholder: "",
			options: [
				{ value: "", label: "" },
				{ value: "AE", label: "Ammunition ship" },
				{ value: "AFS", label: "Combat stores ship " },
				{ value: "AHT", label: "Anchor handling tug" },
				{
					value: "AHTS",
					label: "Anchor handling tug supply vessel",
				},
				{ value: "AO", label: "United States Navy oiler " },
				{ value: "AOG", label: "Gasoline tanker " },
				{ value: "AOR", label: "Auxiliary Replenishment oiler " },
				{ value: "AOT", label: "Transport oiler " },
				{ value: "ATB", label: "Articulated Tug Barge " },
				{ value: "BRP", label: "Barko ng Republika ng Pilipinas " },
				{ value: "CRV", label: "Coastal Research Vessel " },
				{ value: "C/F", label: "Car Ferry" },
				{ value: "CS", label: "Container ship" },
				{ value: "DB", label: "Derrick barge " },
				{ value: "DEPV", label: "Diesel Electric Paddle Vessel " },
				{ value: "DLB", label: "Derrick Lay Barge " },
				{ value: "DCV", label: "Deepwater Construction Vessel" },
				{ value: "DSV", label: "Diving support vessel" },
				{ value: "DV", label: "Dead vessel" },
				{
					value: "ERRV",
					label: "Emergency Response Rescue Vessel",
				},
				{ value: "EV", label: "Exploration Vessel " },
				{
					value: "FPSO",
					label: "Floating production storage and offloading vessel",
				},
				{ value: "FPV", label: "Free Piston Vessel" },
				{
					value: "FT",
					label: "Factory Ship Factory Stern Trawler ",
				},
				{ value: "FV", label: "Fishing Vessel " },
				{
					value: "GTS",
					label: "Gas Turbine Ship ",
				},
				{ value: "HLV", label: "Heavy lift Vessel" },
				{
					value: "HMHS",
					label: "Her(/His) Majesty's Hospital Ship",
				},
				{ value: "HSC", label: "High speed Craft" },
				{ value: "HSF", label: "High Speed Ferry " },
				{ value: "HTV", label: "Heavy transport vessel " },
				{ value: "IRV", label: "International Research Vessel " },
				{ value: "ITB", label: "Integrated Tug barge " },
				{ value: "LB", label: "Liftboat" },
				{
					value: "LNG/C",
					label: "LNG carrier Liquefied natural gas carrier",
				},
				{
					value: "LPG/C",
					label: "LPG carrier Liquefied petroleum gas carrier",
				},
				{ value: "MF", label: "Motor ferry " },
				{ value: "MFV", label: "Motor Fishing Vessel" },
				{ value: "MS (M/S", label: "Motor ship." },
				{
					value: "MSV",
					label: "Multipurpose support/supply vessel ",
				},
				{ value: "MSY", label: "Motor sailing Yacht " },
				{ value: "MT", label: "Motor Tanker" },
				{
					value: "MTS",
					label: "Marine Towage and salvage/tugboat ",
				},
				{ value: "MV", label: "(M/V) Motor Vessel" },
				{ value: "MY", label: "(M/Y) Motor Yacht" },
				{ value: "NB", label: "Narrowboat " },
				{ value: "NRV", label: "NATO Research Vessel " },
				{ value: "NS", label: "Nuclear ship " },
				{ value: "OSV", label: "Offshore supply vessel " },
				{ value: "PS", label: "Paddle steamer " },
				{ value: "PSV", label: "Platform supply vessel " },
				{ value: "QSMV", label: "Quadraple screw motor vessel" },
				{
					value: "QTEV",
					label: "Quadraple turbo electric vessel",
				},
				{ value: "RMS", label: "Royal Mail Ship" },
				{ value: "RNLB", label: "Royal National Lifeboat " },
				{
					value: "RRS",
					label: "Royal Research Ship Royal Research Ship ",
				},
				{ value: "RV/RSV", label: "Research vessel" },
				{ value: "SB", label: "Sailing Barge " },
				{ value: "SL", label: "Steam Launch " },
				{ value: "SS(S/S)", label: "Single crew steamship " },
				{ value: "SSCV", label: "Semi-submersible crane vessel " },
				{ value: "SSS", label: "Sea Scout Ship " },
				{ value: "SSV", label: "Sailing school Vessel" },
				{ value: "ST", label: "Steam tug / trawler" },
				{ value: "STS", label: "Sail training ship " },
				{ value: "STV", label: "Sail Training Vessel" },
				{ value: "SV", label: "Sailing Vessel" },
				{ value: "SY", label: "Sailing yacht/ Steam yacht" },
				{ value: "TB", label: "Tug boat" },
				{ value: "TEV", label: "Turbine electric vessel " },
				{ value: "TIV", label: "Turbine Installation Vessel " },
				{
					value: "TrSS",
					label: "Triple-screw steamship or steamer",
				},
				{ value: "TS", label: "Training Ship" },
				{ value: "Tr.SMV", label: "Triple-Screw Motor Vessel" },
				{ value: "TSMV", label: "Twin-Screw Motor Vessel" },
				{ value: "TSS", label: "Twin-screw steamship or steamer" },
				{ value: "TST", label: "Twin-screw tug" },
				{ value: "TV", label: "Training vessel " },
				{ value: "ULCC", label: "Ultra Large Crude Carrier " },
				{ value: "VLCC", label: "Very Large Crude Carrier " },
				{ value: "YD", label: "Yard derrick " },
				{ value: "YT", label: "Yard Tug " },
				{ value: "YMT", label: "Yard Motor Tug " },
				{ value: "YTB", label: "Yard Tug Big " },
				{ value: "YTL", label: "Yard Tug Little " },
				{ value: "YTM", label: "Yard Tug Medium " },
				{ value: "YW", label: "Water barge, self-propelled" },
				{ value: "YWN", label: "Water barge, none propelled " },
				{ value: "YOS", label: "Concrete vessel " },
			],
		},
		vesselName: {
			label: "Vessel name",
			placeholder: "",
		},
		yearBuildDate: {
			label: "Year build date",
			placeholder: "",
		},
		nameOfTheOwner: {
			label: "Name of the owner",
			placeholder: "",
		},
		addressOfTheOwner: {
			label: "Address of the owner",
			placeholder: "",
		},
		isExhauseGasCleanerScrubberProvided: {
			label: "Vessel is provided with Exhaust Gas Cleaning Scrubber?",
			placeholder: ""
		},
		callSign: {
			label: "Call sign",
			placeholder: "",
		},
		mmsiNumber: {
			label: "MMSI",
			placeholder: ""
		},
		flag: {
			label: "Country Flag",
			placeholder: "",
		},
		nationality: {
			label: "Nationality",
			placeholder: "",
		},
		distinctiveNumberOrLetters: {
			label: 'Distinctive Numbers or letters',
			placeholder: ""
		},
		portOfRegistry: {
			label: "Port of registry",
			placeholder: "",
		},
		vesselType: {
			label: "Vessel type",
			placeholder: "",
			options: [
				{ value: "", label: "" },
				{ label: "Dry", value: "Dry" },
				{ label: "Liquid", value: "Liquid" },
			],
		},
		vesselSubType: {
			label: "Vessel sub type",
			placeholder: "",
			options: [
				{ value: "", label: "" },
				{
					label: "General Cargo Carrier",
					value: "General Cargo Carrier",
				},
				{
					label: "Container Carrier",
					value: "Container Carrier",
				},
				{ label: "RO-RO Carrier", value: "RO-RO Carrier" },
				{ label: "Bulk Carrier", value: "Bulk Carrier" },
				{ label: "Crude Carrier", value: "Crude Carrier" },
				{
					label: "Product Carrier",
					value: "Product Carrier",
				},
				{
					label: "Liquefied Carrier",
					value: "Liquefied Carrier",
				},
				{
					label: "Chemical Carrier",
					value: "Chemical Carrier",
				},
			],
		},
		shipSizeCategory: {
			label: "Ship size category",
			placeholder: "",
			options: [
				{ value: "", label: "" },
				{
					label: "Small Handy Size",
					value: "Small Handy Size",
				},
				{ label: "Seawaymax", value: "Seawaymax" },
				{ label: "Handy Size", value: "Handy Size" },
				{ label: "Handy Max", value: "Handy Max" },
				{ label: "Panamax", value: "Panamax" },
				{ label: "Neopanamax", value: "Neopanamax" },
				{ label: "Capsize", value: "Capsize" },
				{ label: "Chinamax", value: "Chinamax" },
				{ label: "Aframax", value: "Aframax" },
				{ label: "Q-Max", value: "Q-Max" },
				{ label: "Suezmax", value: "Suezmax" },
				{ label: "VLCC", value: "VLCC" },
				{ label: "ULCC", value: "ULCC" },
				{ label: "Malaccamax", value: "Malaccamax" },
			],
		},
		image: {
			label: "Image",
			placeholder: "Upload Vessel image",
		},
		tonnage: {
			label: "Tonnage",
			placeholder: "",
			subFields: {
				grossTonnage: {
					label: "Gross tonnage",
					placeholder: "",
				},
				regGrossTonnage: {
					label: "Reg gross tonnage",
					placeholder: "",
				},
				deadWeight: {
					label: "Dead weight",
					placeholder: "",
				},
				netTonnage: {
					label: "Net tonnage",
					placeholder: "",
				},
			},
		},
		dimensions: {
			label: "Dimensions",
			placeholder: "",
			subFields: {
				length: { label: "Length", placeholder: "" },
				width: { label: "Width", placeholder: "" },
				height: { label: "Height", placeholder: "" },
				draft: { label: "Draft", placeholder: "" },
				loa: { label: "Loa", placeholder: "" },
			},
		},
		hull: {
			label: "Hull",
			placeholder: "",
			subFields: {
				dateofdelivery: { label: "Date Of Delivery", placeholder: "" },
				length: { label: "Length", placeholder: "" },
				builder: { label: "Builder", placeholder: "" },
				breadth: { label: "Breadth", placeholder: "" },
				summerdeadweight: { label: "Summer Dead Weight", placeholder: "" },
				loadeddraught: { label: "Loaded Draught", placeholder: "" },
			},
		},
		mainengine: {
			label: "Main Engine",
			placeholder: "",
			subFields: {
				maker: { label: "Maker", placeholder: "" },
				bore: { label: "Bore", placeholder: "" },
				type: {
					label: "Type",
					placeholder: "",
					options: [
						{ value: "", label: "" },
						{ label: "MC", value: "MC" },
						{ label: "MC + ME", value: "MC + ME" },
						{
							label: "MC + ME + CPP - Wartsilla",
							value: "MC + ME + CPP - Wartsilla",
						},
						{
							label: "MC + ME + CPP - Kamema",
							value: "MC + ME + CPP - Kamema",
						},
						{ label: "MC + CPP - Wartsilla", value: "MC + CPP - Wartsilla" },
						{ label: "MC + CPP - Kamema", value: "MC + CPP - Kamema" },
					],
				},
				stroke: { label: "Stroke", placeholder: "" },
				engineno: { label: "Engine No.", placeholder: "" },
				mcrbhpxrpm: { label: "MCR BHP x RPM", placeholder: "" },
				numberofcylinders: { label: "Number of Cylinders", placeholder: "" },
				ncrbhpxrpm: { label: "NCR BHP x RPM", placeholder: "" },
			},
		},
		propeller: {
			label: "Propeller",
			placeholder: "",
			subFields: {
				manufacturer: { label: "Manufacturer", placeholder: "" },
				diameter: { label: "diameter", placeholder: "" },
				type: { label: "Type", placeholder: "" },
				stroke: { label: "Stroke", placeholder: "" },
				material: { label: "Material", placeholder: "" },
				constant: { label: "Constant", placeholder: "" },
			},
		},
		auxiliaryengines: {
			label: "Auxiliary Engines",
			placeholder: "",
			subFields: {
				maker: { label: "Maker", placeholder: "" },
				bore: { label: "Bore", placeholder: "" },
				type: { label: "Type", placeholder: "" },
				stroke: { label: "Stroke", placeholder: "" },
				engineno: { label: "Engine No", placeholder: "" },
				rpm: { label: "RPM", placeholder: "" },
				typeofgen: { label: "Type of Gen", placeholder: "" },
				outputpowerkw: { label: "Output Power kW", placeholder: "" },
			},
		},
	};
}
const vesselListSelectors = (vesselStore: IVesselStore) => {
	function getOnboardStatusMessage(vessels: IListObjectStore<IVessel>): {
		totalVessels: number;
		onboardedVessels: number;
	} {
		if (vessels.ids.length === 0)
			return {
				totalVessels: 0,
				onboardedVessels: 0,
			};
		const onboardedVessels = vessels.ids.filter(
			(id) =>
				vessels.byIds[id].isCrewOnboarded && vessels.byIds[id].isDeviceOnboarded
		);
		return {
			onboardedVessels: onboardedVessels.length,
			totalVessels: vessels.ids.length,
		};
	}

	function getVesselListData(): IVessel_List__Get {
		const listLoadJob = jobSelectors.getJobStatus(
			vesselStore,
			VESSEL_LIST_LOAD
		);
		return {
			vessels: vesselStore.vessels,
			listLoadJob,
			onboardStatus: getOnboardStatusMessage(vesselStore.vessels),
		};
	}
	return {
		getOnboardStatusMessage,
		getVesselListData,
	};
};

const vesselItemSelectors = (vessel: IVessel) => {
	function isOnboarded() {
		if (vessel) return vessel.isCrewOnboarded && vessel.isDeviceOnboarded;
		return false;
	}
	function getPendingOnboards() {
		const pendingOnboards = [];
		if (vessel.isCrewOnboarded) pendingOnboards.push("CREW");
		if (vessel.isDeviceOnboarded) pendingOnboards.push("DEVICE");
		return pendingOnboards;
	}
	return {
		isOnboarded,
		getPendingOnboards,
	};
};
function getVesselById(vesselId: string, vessels: IListObjectStore<IVessel>) {
	return vessels.byIds[vesselId];
}
function getVesselNameById(
	vesselId: string,
	vessels: IListObjectStore<IVessel>
) {
	const vessel = vessels.byIds[vesselId];
	if (vessel)
		return {
			prefix: vessel.vesselPrefix,
			vesselName: vessel.vesselName,
		};
	return undefined;
}
function getVessels(store: IVesselStore) {
	return store.vessels;
}
function getMyVessels(store: IVesselStore) {
	return store.myVessels;
}
function getVesselListLoadJob(store: IVesselStore) {
	return jobSelectors.getJobStatus(store, VESSEL_LIST_LOAD);
}
function getVesselListLoadAllJob(store: IVesselStore) {
	return jobSelectors.getJobStatus(store, VESSEL_LIST_LOAD_NEW);
}
function getMyVesselListLoadJob(store: IVesselStore) {
	return jobSelectors.getJobStatus(store, MY_VESSELS_LOAD);
}
function areVesselsPresent(store: IVesselStore) {
	return !!store.vessels.ids.length;
}
function getSelectedTab(store: IVesselStore) {
	return store.selectedTab;
}

export default {
	_getStore(store: IStore) {
		return store.app.vesselStore;
	},
	/**
	 * @description returns a list object with only `id`, `vesselName` and `imoNumber` of Vessel
	 * @param vessels IListObjectStore of IVessel
	 */
	getMiniVessels(vessels: IListObjectStore<IVessel>) {
		const miniVessels: IListObjectStore<{
			vesselName: string;
			imoNumber: string;
			id: string;
		}> = {
			byIds: {},
			ids: vessels.ids,
		};
		vessels.ids.map((id) => {
			miniVessels.byIds[id] = {
				id,
				vesselName: vessels.byIds[id].vesselName,
				imoNumber: vessels.byIds[id].imoNumber,
			};
		});
		return miniVessels;
	},
	areVesselsPresent,
	getVesselById,
	getVesselNameById,
	getVessels,
	getMyVessels,
	jobToLoadVessels: getVesselListLoadJob,
	jobToLoadAllVessels: getVesselListLoadAllJob,
	jobToLoadMyVessels: getMyVesselListLoadJob,
	vesselListSelectors,
	vesselItemSelectors,
	getVesselFormStaticData,
	getSelectedTab,
	jobToLoadVesselsNew: (store: IVesselStore) => {
		return jobSelectors.getJobStatus(store, VESSEL_LIST_LOAD_NEW);
	},
	jobToAssignVesselsToFleet: (store: IVesselStore) => {
		return jobSelectors.getJobStatus(store, FLEET_SELECTED_VESSEL_ASSIGN);
	},
	jobToCreateFleet: (store: IVesselStore) => {
		return jobSelectors.getJobStatus(store, FLEET_CREATE);
	},
};
