import React, { useEffect, useMemo, useState } from 'react'
import RangeManagementList from './List'
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from 'redux/store';
import { Dispatch } from 'redux';
import { vesselOnboardActions, vesselOnboardSelectors } from 'redux/app/vessel/onboard';
import { ILocalRange, Ranges, TRangeManagement } from 'redux/app/vessel/onboard/types';
import { RANGE_MANAGEMENT_PER_PAGE } from 'redux/app/vessel/onboard/constants';
import { IPagination } from 'redux/app/tool/@types';
import { Tab, Tabs } from '@blueprintjs/core';
import NumberedPagination from 'components/_common/NumberedPagination';
import RangeManagementForm from './RangeManagementForm';
import ConfirmBox from 'components/app/tool/configandprint/_elements/ConfirmBox';
import ModalPortal from "components/app/_common/ModalPortal";
import Button from "@set-product/core/button/Button";

export enum ModalType {
    Add = 'Add',
    Edit = 'Edit',
    Delete = 'Delete',
    Remarks = 'Remarks',
}

export enum ERangeType {
    Local = 'Local Ranges',
    Config = 'Config Ranges',
}

type OwnProps = {};
type RangeManagementProps = PropsFromRedux & OwnProps;
function RangeManagement(props: RangeManagementProps) {
	const { configListLoadJob, configLoadJob, localRangesLoadJob } = props.jobs;
	const [totalRanges, setTotalRanges] = useState<ILocalRange[]>([]);
	const [ranges, setRanges] = useState<ILocalRange[]>([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(ModalType.Add);
	const [localRange, setLocalRange] = useState<ILocalRange>({
        key: '',
        metric: '',
        range: [undefined, undefined],
        remarks: '',
        isLocalRange: true,
    });
	
	const [rangeType, setRangeType] = useState(ERangeType.Local);
	const [pagination, setPagination] = useState<IPagination>({
		itemsPerPage: RANGE_MANAGEMENT_PER_PAGE,
		currentPage: 1,
	});

	useEffect(() => {
		props.loadVesselRanges();
		props.loadVesselConfigList();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.vesselId]);

	useEffect(() => {
		const id = props?.configLogsList?.ids?.find((id) => {
			if (props?.configLogsList?.byIds?.[id]?.type === 'engineLogConfig') {
				return true;
			}
			return false;
		});
		if (id) {
			props.loadVesselConfig(props?.configLogsList?.byIds?.[id]?._id);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.configLogsList]);
	
	const localRanges = useMemo(() => {
		const ranges: ILocalRange[] = Object.entries(props.ranges)?.filter(([key, range]) => {
			return !!range;
		})?.map(([key, range]) => {
			return {
				...range,
				key,
			};
		});
		return ranges;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.ranges])

    const configRanges = useMemo(() => {
        const logCategories = props?.configInfo?.config?.logCategories;
		const newConfigRanges: ILocalRange[] = [];
		const traverseMetrics = (metrics: any[], anotherTitle: string) => {
			if (!metrics) return;
			metrics?.forEach((metric: any) => {
				const title = metric?.title
					? (metric?.title as string)
					: '';
				if (
					metric?.readingRange?.dataType === 'integer' &&
					metric?.readingRange?.range
				) {
					newConfigRanges.push({
						key: metric?.key,
						metric: title + ' > ' + anotherTitle,
						remarks: metric?.remarks,
						range: metric?.readingRange?.range || ['', ''],
					});
				}
			});
		};
		if (logCategories) {
			logCategories?.forEach((category: any) => {
				const categoryTitle = category?.title
					? category?.title
					: '';
				category?.sections?.forEach((section: any) => {
					const sectionTitle =
						`${section?.title ? section?.title + ' > ' : ''}` +
						categoryTitle;
					traverseMetrics(section?.metrics, sectionTitle);
					section?.modules?.forEach((m: any) => {
						const mTitle =
							`${m?.title ? m?.title + ' > ' : ''}` +
							sectionTitle;
						traverseMetrics(m?.metrics, mTitle);
						m?.subModules?.forEach((subM: any) => {
							const subMTitle =
								`${
									subM?.title ? subM?.title + ' > ' : ''
								}` + mTitle;
							traverseMetrics(subM?.metrics, subMTitle);
						});
					});
				});
			});
			return newConfigRanges;
		}
		return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.configInfo?.config?.logCategories]);

	useEffect(() => {
		if (rangeType === ERangeType.Local) {
			setTotalRanges(localRanges);
		} else {
			setTotalRanges(configRanges);
		}
	}, [configRanges, localRanges, rangeType]);

	useEffect(() => {
		const { currentPage } = pagination;
		const start = (currentPage - 1) * RANGE_MANAGEMENT_PER_PAGE;
		setRanges(totalRanges.slice(start, start + RANGE_MANAGEMENT_PER_PAGE));
	}, [totalRanges, pagination]);

	const isLoading = useMemo(() => {
		return localRangesLoadJob.isLoading || configListLoadJob.isLoading || configLoadJob.isLoading;
	}, [localRangesLoadJob.isLoading, configListLoadJob.isLoading, configLoadJob.isLoading]);

	return (
		<>
			<ModalPortal
				isActive={modalOpen}
				onClose={() => {
					setModalOpen(false);
                    setLocalRange({
                        key: '',
                        metric: '',
                        range: [undefined, undefined],
                        remarks: '',
                    });
                    setModalType(ModalType.Add);
				}}
				isOverlayClickCloseable={false}
				width={500}
			>
				{modalOpen ?
					modalType === ModalType.Delete? 
						<ConfirmBox
							title="Are you sure you want to delete this range?"
							closeBtnLabel="Close"
							confirmBtnLabel="Confirm"
							isActive={true}
							onClose={() => {
								setModalOpen(false);
								setLocalRange({
									key: '',
									metric: '',
									range: [undefined, undefined],
									remarks: '',
								});
								setModalType(ModalType.Add);
							}}
							onConfirm={() => {	
								props.deleteVesselRanges({
									[localRange.key]: null as any,
								});					
								setModalOpen(false);
								setLocalRange({
									key: '',
									metric: '',
									range: [undefined, undefined],
									remarks: '',
								});
								setModalType(ModalType.Add);
							}}
							modalSize={500}
							titleClassName='ws-input__label text-lg pt-0'
						/>
					: (
						<RangeManagementForm
							localRange={localRange}
							modalType={modalType}
							onCancel={() => {
								setModalOpen(false);
								setLocalRange({
									key: '',
									metric: '',
									range: [undefined, undefined],
									remarks: '',
								});
								setModalType(ModalType.Add);
							}}
							onSubmit={(info) => {
								if (info) {
									if (modalType === ModalType.Edit) {
										props.editVesselRanges({
											[info.key]: {
												...localRange,
												...info
											} as any,
										});
									} else {
										props.addVesselRanges({
											[info.key]: {
												...info,
											} as any,
										});
									}
								}
								setModalOpen(false);
								setLocalRange({
									key: '',
									metric: '',
									range: [undefined, undefined],
									remarks: '',
								});
								setModalType(ModalType.Add);
							}}
							rangeManagement={props.configInfo as TRangeManagement}
						/>
					) : null
				}
			</ModalPortal>
			<div className='px-4'>
				<div className='flex items-center'>
					<Tabs
						id="ranges-tabs"
						animate={true}
						renderActiveTabPanelOnly={true}
						vertical={false}
						large={true}
						selectedTabId={rangeType}
						className="w-full rounded-sm bp3-html-table bp3-interactive overflow-x-auto"
						onChange={function onTabsChange(tabId) {
							setPagination((prev) => {
								return {
									...prev,
									currentPage: 1,
								};
							});
							setRangeType(tabId as ERangeType);
						}}
					>
						<Tab
							id={ERangeType.Local}
							disabled={false}
							className="font-medium text-gray-600 "
						>
							Local Ranges ({localRanges.length})
						</Tab>
						<Tab
							id={ERangeType.Config}
							disabled={false}
							className="font-medium text-gray-600 "
						>
							Config Ranges ({configRanges.length})
						</Tab>
						<Tabs.Expander />
					</Tabs>
					<div>
						<Button
							className="bg-transparent outline-none flex items-center justify-center shadow-none border border-solid border-gray-300 border-b-0 rounded-none rounded-t-md"
							onClick={() => {
								setModalType(ModalType.Add);
								setModalOpen(true);
								setLocalRange({
									key: '',
									metric: '',
									range: [undefined, undefined],
									remarks: '',
								});
							}}
						>
							<span className="text-lg text-blue-500 bp3-icon bp3-icon-add" />
						</Button>
					</div>
				</div>
				<div className="border-t border-gray-300" />
			</div>
			<RangeManagementList
				rangeType={rangeType}
				ranges={ranges}
				isLoading={isLoading}
				sNoStart={
					pagination.currentPage * pagination.itemsPerPage -
					pagination.itemsPerPage
				}
				onDelete={(range) => {
					setModalType(ModalType.Delete);
					setModalOpen(true);
					setLocalRange(range);
				}}
				onEdit={(range) => {
					setModalType(ModalType.Edit);
					setModalOpen(true);
					setLocalRange(range);
				}}
			/>
			<NumberedPagination
				{...{
					...pagination,
					totalItems: totalRanges.length || 0,
				}}
				itemIds={[]}
				onPageChange={function onPageChange(pageNumberFromPagination) {
					setPagination((prev) => {
						return {
							...prev,
							currentPage: pageNumberFromPagination,
						};
					});
				}}
				isPageLoading={false}
			/>
		</>
	);
}

const mapStateToProps = (store: IStore) => vesselOnboardSelectors.getRangesData(store.app.vesselStore.onboard);
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadVesselRanges() {
			dispatch(vesselOnboardActions.command.vesselRangesLoad());
		},
		deleteVesselRanges(ranges: Ranges) {
			dispatch(vesselOnboardActions.command.vesselRangeDelete(ranges));
		},
		editVesselRanges(ranges: Ranges) {
			dispatch(vesselOnboardActions.command.vesselRangeEdit(ranges));
		},
		addVesselRanges(ranges: Ranges) {
			dispatch(vesselOnboardActions.command.vesselRangeAdd(ranges));
		},
		loadVesselConfigList() {
			dispatch(vesselOnboardActions.command.vesselConfigListLoad());
		},
		loadVesselConfig(configId: string) {
			dispatch(vesselOnboardActions.command.vesselConfigLoad(configId));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(RangeManagement);