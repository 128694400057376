import { IAction, IListObjectStore } from "../../_common/type";
import { IForm, IFormStore } from "../../_core/form/types";
import { IJobHOCState, IJobGetStatus } from "../../_core/job/types";
import vesselConstants, { TAB_IDS } from "./constants";
import { IVesselOnboardStore } from "./onboard/types";

type ITonnage = {
	grossTonnage: number;
	regGrossTonnage: number;
	deadWeight: number;
	netTonnage: number;
};
type IDimensions = {
	length: number;
	width: number;
	height: number;
	draft: number;
	loa: number;
};

type IHull = {
	dateofdelivery: string;
	length: number;
	builder: string;
	breadth: number;
	summerdeadweight: number;
	loadeddraught: number;
};

type IMainEngine = {
	maker: string;
	bore: string;
	type: string; //dropdown
	stroke: string;
	engineno: string;
	mcrbhpxrpm: number;
	numberofcylinders: number;
	ncrbhpxrpm: number;
};

type IPropeller = {
	manufacturer: string;
	diameter: string;
	type: string;
	stroke: string;
	material: string;
	constant: string;
};

type IAuxiliaryEngines = {
	maker: string;
	bore: string;
	type: string;
	stroke: string;
	engineno: string;
	rpm: number;
	typeofgen: string;
	outputpowerkw: number;
};

type IVersionInfo = {
	FE: string;
	BE: string;
};

interface ILiveVersionInfo {
	FE: string;
	BE: string;
	config: string;
}

interface IDesignation {
	type: string;
	label: string;
	rank: number;
	name: string;
	vesselId: string;
	id: string;
}

interface ILiveLastUpdatedBy {
	name: string;
	id: string;
	designation: IDesignation;
}
interface ILiveInfoDetails {
	local_reading: string;
	lastUpdatedBy: ILiveLastUpdatedBy;
}

interface ILiveInfoTo extends ILiveInfoDetails {}
interface ILiveInfoFrom extends ILiveInfoDetails {}
interface IStateOfShip extends ILiveInfoDetails {}

type ILiveInfo = {
	versionInfo: ILiveVersionInfo;
	last_synced: string;
	from: ILiveInfoTo;
	to: ILiveInfoFrom;
	state_of_ship: IStateOfShip;
	last_location?: {
		lat: string;
		long: string;
	};
};

export interface IUsabilityAlertDateObj {
	watchHour?: number[];
	isApprove?: boolean[];
	hint: string;
	state: string;
	status?: string[];
}

export enum EVESSEL_ALERTS_STATUS {
	GOOD = "GOOD",
	MEDIUM = "MEDIUM",
	HIGH = "HIGH",
}

export interface IVesselAlertDescriptor {
	description: string;
}

export type TVesselAlertsUsability = Record<string, IUsabilityAlertDateObj>;
export interface IVesselAlerts {
	vesselId: string;
	fromDate: string;
	toDate: string;
	usability: Record<string, TVesselAlertsUsability>;
	alerts: Record<string, TVesselAlertsUsability>;
	status: EVESSEL_ALERTS_STATUS;
	observations: {
		alerts: Record<string, IVesselAlertDescriptor[]>;
		usage: Record<string, IVesselAlertDescriptor[]>;
	};
}

export type IVessel = {
	vesselName: string;
	imoNumber: string;
	yearBuildDate: string;

	portOfRegistry: string;
	nationality: string;
	nameOfTheOwner?: string;
	flag?: string;

	vesselPrefix?:
		| "MV"
		| "MT"
		| "AE"
		| "AFS"
		| "AHT"
		| "AHTS"
		| "AO"
		| "AOG"
		| "AOR"
		| "AOT"
		| "ATB"
		| "BRP"
		| "CRV"
		| "C/F"
		| "CS"
		| "DB"
		| "DEPV"
		| "DLB"
		| "DCV"
		| "DSV"
		| "DV"
		| "ERRV"
		| "EV"
		| "FPSO"
		| "FPV"
		| "FT"
		| "FV"
		| "GTS"
		| "HLV"
		| "HMHS"
		| "HSC"
		| "HSF"
		| "HTV"
		| "IRV"
		| "ITB"
		| "LB"
		| "LNG/C"
		| "LPG/C"
		| "MF"
		| "MFV"
		| "MS (M/S)"
		| "MSV"
		| "MSY"
		| "MT"
		| "MTS"
		| "MV"
		| "MY"
		| "NB"
		| "NRV"
		| "NS"
		| "OSV"
		| "PS"
		| "PSV"
		| "QSMV"
		| "QTEV"
		| "RMS"
		| "RNLB"
		| "RRS"
		| "RV/RSV"
		| "SB"
		| "SL"
		| "SS(S/S)"
		| "SSCV"
		| "SSS"
		| "SSV"
		| "ST"
		| "STS"
		| "STV"
		| "SV"
		| "SY"
		| "TB"
		| "TEV"
		| "TIV"
		| "TrSS"
		| "TS"
		| "Tr.SMV"
		| "TSMV"
		| "TSS"
		| "TST"
		| "TV"
		| "ULCC"
		| "VLCC"
		| "YD"
		| "YT"
		| "YMT"
		| "YTB"
		| "YTL"
		| "YTM"
		| "YW"
		| "YWN"
		| "YOS"
		| undefined;
	vesselType?: "Dry" | "Liquid" | undefined;
	vesselSubType?:
		| "General Cargo Carrier"
		| "Container Carrier"
		| "RO-RO Carrier"
		| "Bulk Carrier"
		| "Crude Carrier"
		| "Product Carrier"
		| "Liquefied Carrier"
		| "Chemical Carrier"
		| undefined;
	isExhauseGasCleanerScrubberProvided?: boolean;
	shipSizeCategory?:
		| "Small Handy Size"
		| "Seawaymax"
		| "Handy Size"
		| "Handy Max"
		| "Panamax"
		| "Neopanamax"
		| "Capsize"
		| "Chinamax"
		| "Aframax"
		| "Q-Max"
		| "Suezmax"
		| "VLCC"
		| "ULCC"
		| "Malaccamax"
		| undefined;

	addressOfTheOwner?: string;
	callSign?: string;
	tonnage?: ITonnage;
	mmsiNumber?: string;
	distinctiveNumberOrLetters?: string;
	dimensions?: IDimensions;
	hull?: IHull;
	mainengine?: IMainEngine;
	propeller?: IPropeller;
	auxiliaryengines?: IAuxiliaryEngines;
	id: string;
	image?: string;
	tenantId?: string;
	createdAt?: string;
	updatedAt?: string;
	isCrewOnboarded?: boolean;
	isDeviceOnboarded?: boolean;
	liveInfo?: ILiveInfo;
	vesselAlerts?: IVesselAlerts;
};

export type IVesselCrew = {
	name: string;
	fromDate?: string;
	toDate?: string;
	designation: string;
	userId?: string;
	staffId: string;
	// image: string;
	vesselId?: string;
	id: string;
};

export type IVesselDevice = {
	id: string;
	deviceType: "TABLET" | "DESKTOP";
	operatingSystem: string;
	deviceName: string;
	version: string;
	macAddress: string;
	deviceId: string;
	vesselId?: string;
};

export type IVesselFilters = {
	imoNumber?: string;
	vesselName?: string;
	vesselType?: "Dry" | "Liquid" | "";
	nameOfTheOwner?: string;
};

export interface IVesselFilterForm
	extends IForm<IVesselFilters, any, any, any> {}

// All Actions below
export type IVessel_ListRemove__Action = IAction<{
	vesselId: string;
}>;
export type IVessel_ListLoad__Action = IAction<any>;
export type IVessel_ListFilter__Action = IAction<IVesselFilters>;
export type IVessel_ListSet__Action = IAction<IListObjectStore<IVessel>>;
export type IVessel_ListReset__Action = IAction<any>;
export type IVessel_ListPush__Action = IAction<IVessel>;
export type IVessel_listEdit__Action = IAction<{
	vesselId: string;
	vessel: IVessel;
}>;
export type ITab_Select = IAction<{ tabSelected: TAB_IDS }>;
export type IVessel_FavoriteSet__Action = IAction<{
	vesselIds: string[];
}>;
export type IFleet_ListSet__Action = IAction<{ fleets: IFleet[] }>;
export type IFleet_IdSet__Action = IAction<{ fleetId: string }>;
export type IEnable_VesselSelection__Action = IAction<{ isSelected: boolean }>;
export type ISelectedVessel_Add__Action = IAction<{ vesselId: string }>;
export type IFleetName_Add__Action = IAction<{ fleetName: string }>;
export type IFleetDropdown_Toggle__Action = IAction<{ isOpen: boolean }>;
export type IVesselCard_OperationAdd__Action = IAction<{
	operation: TVesselCardOperation;
}>;

// All Selectors below
export type IVessel_List__Get = {
	vessels: IListObjectStore<IVessel>;
	listLoadJob: IJobGetStatus;
	onboardStatus: {
		totalVessels: number;
		onboardedVessels: number;
	};
};

export interface IFleet {
	_id: string;
	_rev: string;
	name: string;
	tenantId: string;
	modelName: string;
	createdAt: string;
}

export type TVesselCardOperation = "fav" | "unfav";
export type TPossibleOperations = "fav" | "unfav" | "fav/unfav";

// Vessel Store
export interface IVesselStore extends IFormStore, IJobHOCState {
	vessels: IListObjectStore<IVessel>;
	myVessels: IListObjectStore<IVessel>;
	_forms: {
		FILTER: IVesselFilterForm;
	};
	onboard: IVesselOnboardStore;
	selectedTab: string;
	favoriteVesselIds: string[];
	fleets: IFleet[];
	selectedFleetId: string;
	enableSelection: boolean;
	selectedVesselsOnEnableSelection: string[];
	selectedFleetIdToWhichVesselsToBeAssigned: string;
	fleetName: string;
	isCreateFleetModalOpen: boolean;
	isFleetListDropdownOpen: boolean;
	isAssignVesselsToFleetDropdownOpen: boolean;
	// vessel operation can be favoriting or unfavoriting, this accumulates list of favoriting & unfavoriting done prior to api call.
	operationsPriorToDebounce: TVesselCardOperation[];
}

export interface IUserDetail {
	name: string;
	role: string;
	roleCategory: string;
	staffId: string;
	lastRelievingDay: string;
	isRelieved: boolean;
	email: string;
	password: string;
	id: string;
	tenantId: string;
	createdAt: string;
	updatedAt: string;
	_rev: string;
	username: string;
	favourite_vessels: string[];
}

export interface IVesselOperation {
	vesselId: string | null;
	operation: "ADD_VESSEL" | "UPDATE_VESSEL";
	isModalOpen: boolean;
}

export interface ICreateFleetResponse {
	ok: boolean;
	id: string;
	rev: string;
}

export interface IDashboardStatusType {
	title: string;
	status: string;
	color: string;
}
