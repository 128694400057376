import classNames from "classnames";
import moment from "moment";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import {
	EGarbageRecordState,
	IGarbageAccidentalLoss,
	IGarbageDischargedIntoSea,
	IGarbageDischargedToReceptionAshoreOrOtherShips,
	IGarbageIncinerated,
	IGarbageRecord,
} from "../../../../../redux/app/garbage-record/@types";
import garbageRecordHelper from "../../../../../redux/app/garbage-record/helper";
import { logSelectors } from "../../../../../redux/app/logs";
import {
	marpolActions,
	marpolSelectors,
} from "../../../../../redux/app/marpol";
import {
	vesselActions,
	vesselSelectors,
} from "../../../../../redux/app/vessel";
import { IStore } from "../../../../../redux/store";
import GarbageRecordEditsForPrint from "./GarbageRecordEditsForPrint";

type OwnProps = {
	disabled: boolean;
};
type MARPOLDocsProps = OwnProps & PropsFromRedux;
function GarbageRecordDocs(props: MARPOLDocsProps) {
	const { marpolDocs, disabled } = props;

	if (disabled)
		return (
			<div className="w-full h-64 text-gray-500 bg-gray-500 rounded-lg opacity-50">
				Disabled MARPOL DOCS
			</div>
		);

	if (marpolDocs.length === 0)
		return (
			<div className="flex flex-col flex-1 max-h-full mb-2 ">
				<div className="flex flex-row w-full max-h-full overflow-y-auto ws-scroll">
					<div className="flex-1 ">
						<h1 className="p-6 text-sm font-medium border border-gray-200 rounded-sm">
							No records found..
						</h1>
					</div>
				</div>
			</div>
		);

	return (
		<div className="py-6 ws-scroll">
			<div className="px-6 mb-12">
				<h1 className="mb-8 text-xl font-bold font-redhat-display">
					RECORD OF GARBAGE DISCHARGES
				</h1>
				<div className="mb-3">
					<h3 className="mb-1 text-base font-medium text-gray-700 ">
						Garbage Categories
					</h3>
					<div className="flex flex-row flex-wrap -mx-6">
						<ul className="px-6">
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									A.
								</span>{" "}
								Plastics
							</li>
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									B.
								</span>{" "}
								Food wastes
							</li>
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									C.
								</span>{" "}
								Domestic wastes.
							</li>
						</ul>
						<ul className="px-6">
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									D.
								</span>{" "}
								Cooking Oil
							</li>
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									E.
								</span>{" "}
								Incinetor Ashes
							</li>
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									F.
								</span>{" "}
								Operational wastes
							</li>
						</ul>
						<ul className="px-6">
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									G.
								</span>{" "}
								Animal Carcass
							</li>
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									H.
								</span>{" "}
								Fishing Gear
							</li>
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									I.
								</span>{" "}
								E-Waste
							</li>
						</ul>
					</div>
				</div>
				<h1 className="w-3/4 font-medium leading-5 tracking-wide text-gray-600">
					Discharges under MARPOL Annex V regulations 4 (Discharge of garbage
					outside special areas), 5 (Special requirements for discharge of
					garbage from fixed or floating platform) or 6(Discharge of garbage
					withing special areas) or chapter 5 of part II-A of the Polar Code
				</h1>
			</div>
			<div className="px-6 mb-12 rounded-md">
				<table>
					<thead>
						<tr>
							<th
								className="p-3 font-medium tracking-wide text-left text-gray-700 align-top bg-white border border-gray-400"
								rowSpan={2}
							>
								Date<span className="invisible">/Time</span>
							</th>
							<th
								className="p-3 font-medium tracking-wide text-left text-gray-700 align-top bg-white border border-gray-400"
								rowSpan={2}
							>
								Position of the ship (latitude/longitude) or port if disposed of
								ashore or name of ship if discharged to another ship
							</th>
							<th
								className="p-3 font-medium tracking-wide text-left text-gray-700 align-top bg-white border border-gray-400"
								rowSpan={2}
							>
								{/* <div className="relative">
                                    <div className="absolute top-0 bottom-0 left-0 right-0 mt-16 mr-4 text-sm tracking-widest transform -rotate-90">
                                        Category
                                    </div>
                                </div> */}
								Category
							</th>
							<th
								className="p-3 font-medium tracking-wide text-left text-gray-700 align-top bg-white border border-gray-400"
								colSpan={2}
							>
								Estimated amount discharged
							</th>
							<th
								className="p-3 font-medium tracking-wide text-left text-gray-700 align-top bg-white border border-gray-400"
								rowSpan={2}
							>
								Estimated amount incinerated (m<sup>3</sup>)
							</th>
							<th
								className="p-3 font-medium tracking-wide text-left text-gray-700 align-top bg-white border border-gray-400"
								rowSpan={2}
							>
								Remarks (eg start/stop time and position of incineration;
								general remarks)
							</th>
							<th
								className="p-3 font-medium tracking-wide text-left text-gray-700 align-top bg-white border border-gray-400"
								rowSpan={2}
							>
								Approved and Signed by
							</th>
						</tr>
						<tr>
							<th className="p-3 text-sm font-medium tracking-wide text-left text-gray-700 align-top bg-white border border-gray-400">
								To sea (m<sup>3</sup>)
							</th>
							<th className="p-3 text-sm font-medium tracking-wide text-left text-gray-700 align-top bg-white border border-gray-400">
								To reception facilities/ another ship (m
								<sup>3</sup>)
							</th>
						</tr>
					</thead>
					<tbody>
						{marpolDocs
							.sort((record1, record2) =>
								moment(record1.selectedDate, "YYYY-MM-DD").isBefore(
									moment(record2.selectedDate, "YYYY-MM-DD")
								)
									? -1
									: 0
							)
							.map((record) => (
								<React.Fragment key={record.id}>
									{Array.isArray(record.garbage)
										? record.garbage.map((garbage, index) => {
												return (
													<tr
														key={index}
														className={classNames("leading-5", {
															"line-through":
																record.state ===
																EGarbageRecordState.STRIKED_OFF,
														})}
													>
														<td
															className="p-3 px-6 font-normal text-left text-gray-800 border border-gray-400"
															rowSpan={record.editHistory.length > 0 ? 2 : 1}
														>
															<div className="h-full">
																<span className="block font-medium whitespace-nowrap">
																	{moment(
																		record.selectedDate,
																		"YYYY-MM-DD"
																	).format("DD-MMM-YYYY")}
																</span>
															</div>
														</td>
														<td className="p-3 px-6 font-normal leading-5 text-left text-gray-800 border border-gray-400">
															{(() => {
																switch (record.entry) {
																	case "GARBAGE_INCINERATED":
																		return (
																			<div className="h-full leading-5 font-redhat-text">
																				<span className="block font-medium whitespace-nowrap">
																					Lat{" "}
																					{
																						(record as IGarbageIncinerated)
																							.locationOfStartOfIncineration
																							.latitude
																					}
																				</span>
																				<span className="block font-medium whitespace-nowrap">
																					Long{" "}
																					{
																						(record as IGarbageIncinerated)
																							.locationOfStartOfIncineration
																							.longitude
																					}
																				</span>
																			</div>
																		);
																	case "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA":
																		return (
																			<div className="h-full leading-5 font-redhat-text">
																				<span className="block font-medium whitespace-nowrap">
																					{
																						(record as IGarbageAccidentalLoss)
																							.locationOfOccurance
																					}
																				</span>
																			</div>
																		);
																	case "GARBAGE_DISCHARGED_INTO_SEA":
																		return (
																			<div className="h-full leading-5 font-redhat-text">
																				<span className="block font-medium whitespace-nowrap">
																					Lat{" "}
																					{
																						(
																							record as IGarbageDischargedIntoSea
																						).locationOfStartOfDischarge
																							.latitude
																					}
																				</span>
																				<span className="block font-medium whitespace-nowrap">
																					Long{" "}
																					{
																						(
																							record as IGarbageDischargedIntoSea
																						).locationOfStartOfDischarge
																							.longitude
																					}
																				</span>
																			</div>
																		);
																	case "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS":
																		return (
																			<div className="h-full leading-5 font-redhat-text">
																				<span className="block font-medium whitespace-nowrap">
																					{
																						(
																							record as IGarbageDischargedToReceptionAshoreOrOtherShips
																						).placeOfDischarge
																					}
																				</span>
																				<span className="block font-medium whitespace-nowrap">
																					{" "}
																					{
																						(
																							record as IGarbageDischargedToReceptionAshoreOrOtherShips
																						).placeOfDischarge
																					}
																				</span>
																			</div>
																		);
																}
															})()}
														</td>
														<td className="p-3 px-6 font-medium text-left text-gray-800 border border-gray-400">
															<div className="h-full font-medium">
																{garbage.category}
															</div>
														</td>
														<td className="p-3 px-6 font-normal text-left text-gray-800 border border-gray-400">
															<h4 className="font-medium capitalize">
																{(record.entry ===
																	"GARBAGE_DISCHARGED_INTO_SEA" ||
																	record.entry ===
																		"ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA") &&
																garbage
																	? garbage.estimatedAmountDischarged
																	: "-"}
															</h4>
														</td>
														<td className="p-3 px-6 font-normal text-left text-gray-800 border border-gray-400">
															<div className="h-full font-medium">
																{record.entry ===
																	"GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS" &&
																garbage
																	? garbage.estimatedAmountDischarged
																	: "-"}
															</div>
														</td>
														<td className="p-3 px-6 font-normal text-left text-gray-800 border border-gray-400">
															<div className="h-full font-medium">
																{record.entry === "GARBAGE_INCINERATED" &&
																garbage
																	? garbage.estimatedAmountDischarged
																	: "-"}
															</div>
														</td>
														<td className="p-3 px-6 font-normal text-left text-gray-800 border border-gray-400">
															<div className="h-full font-medium leading-6 tracking-wide">
																{record.remark}
																{record.strikeOffReason ? (
																	<div className="inline-block">
																		<br />
																		<div className="font-bold tracking-wide text-red-600">
																			STRIKE OFF REASON:
																		</div>
																		<div>{record.strikeOffReason}</div>
																	</div>
																) : null}
															</div>
														</td>
														<td
															className="p-3 px-6 font-normal text-left text-gray-800 border border-gray-400 "
															style={{
																textDecoration: "none",
															}}
														>
															{record.state === EGarbageRecordState.APPROVED ? (
																<div className="h-full font-medium">
																	<h4>{record.approvedBy?.name}</h4>
																	<h4>{record.approvedBy?.designation}</h4>
																	<h4>{record.approvedBy?.staffId}</h4>
																</div>
															) : (
																<div className="h-full font-medium">--</div>
															)}
														</td>
													</tr>
												);
										  })
										: null}
									{record.editHistory.length > 0 ? (
										<tr key={record.id}>
											<td
												colSpan={7}
												className="p-3 px-6 font-normal text-left text-gray-800 border border-gray-400 "
												style={{
													textDecoration: "none",
												}}
											>
												<GarbageRecordEditsForPrint
													garbageRecordForView={record}
												/>
											</td>
										</tr>
									) : null}
								</React.Fragment>
							))}
					</tbody>
				</table>
			</div>
			<div className="px-6 mb-12 rounded-md"></div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const logStore = logSelectors._getStore(store);
	const vesselStore = vesselSelectors._getStore(store);
	const marpolStore = marpolSelectors._getStore(store);

	return {
		areVesselsPresent: vesselSelectors.areVesselsPresent(store.app.vesselStore),
		filterForm: logSelectors.formForFilter(logStore),
		marpolDocs: garbageRecordHelper.getHistoryList(
			marpolStore // marpolSelectors.getDocs(marpolStore) as IGarbageRecord[]
		),
		miniVessels: vesselSelectors.getMiniVessels(
			vesselSelectors.getVessels(vesselStore)
		),
		jobToFilterLogs: logSelectors.jobToFilterLogs(logStore),
		jobToLoadSelectedLog: logSelectors.jobToLoadSelectedLog(logStore),
		jobToLoadVessels: vesselSelectors.jobToLoadVessels(vesselStore),
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadVessels() {
			dispatch(vesselActions.listLoad(true));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GarbageRecordDocs);
