import { Reducer, Action } from "redux";
import actionTypes from "./action-types";
import {
	IVesselOnboardStore,
	IVesselOnboard_crewListSet__Action,
	IVesselOnboard_deviceListSet__Action,
	IVesselOnboard_crewNew__Action,
	IVesselOnboard_crewSet__Action,
	IVesselOnboard_crewEdit__Action,
	IVesselOnboard_crewErase__Action,
	IVesselOnboard_deviceNew__Action,
	IVesselOnboard_deviceSet__Action,
	IVesselOnboard_deviceEdit__Action,
	IVesselOnboard_deviceErase__Action,
	IVesselOnboard_reset__Action,
	IVesselOnboard_crewDesignationSet__Action,
	IVesselOnboard_DashboardUsageAndAlertsSet__Action,
	IVesselOnboard_rangesSet__Action,
	IVesselOnboard_configLogsListSet__Action,
	IVesselOnboard_configInfoSet__Action,
	IVesselOnboard_timeLogsSet__Action,
	IVesselOnboard_timeLogsAppend__Action
} from "./types";
import withForms from "../../../_core/form";
import withJobs from "../../../_core/job/reducers";
import reducerState from "./reducers.state";
import { TMARPOLTank } from "redux/app/marpol/@types";

const {
	WIZARD_CREW_LIST_SET,
	WIZARD_DEVICE_LIST_SET,
	WIZARD_CREW_NEW,
	WIZARD_CREW_SET,
	WIZARD_CREW_EDIT,
	WIZARD_CREW_ERASE,
	WIZARD_DEVICE_NEW,
	WIZARD_DEVICE_SET,
	WIZARD_DEVICE_EDIT,
	WIZARD_DEVICE_ERASE,
	WIZARD_RESET,
	WIZARD_CREW_DESIGNATIONS_SET,
	WIZARD_VESSEL_ID_SET,
	TANKS_SET,
	WIZARD_DASHBOARD_USAGE_ALERTS_SET,
	WIZARD_VESSEL_RANGES_SET,
	WIZARD_VESSEL_CONFIG_SET,
	WIZARD_VESSEL_CONFIG_LIST_SET,
	SET_TIME_LOGS,
	APPEND_TIME_LOGS,
} = actionTypes.document;

const vesselOnboardReducers: Reducer<IVesselOnboardStore> = (
	state: IVesselOnboardStore = reducerState,
	action:
		| IVesselOnboard_timeLogsSet__Action
		| IVesselOnboard_timeLogsAppend__Action
		| IVesselOnboard_crewListSet__Action
		| IVesselOnboard_deviceListSet__Action
		| IVesselOnboard_crewNew__Action
		| IVesselOnboard_crewSet__Action
		| IVesselOnboard_crewEdit__Action
		| IVesselOnboard_crewErase__Action
		| IVesselOnboard_deviceNew__Action
		| IVesselOnboard_deviceSet__Action
		| IVesselOnboard_deviceEdit__Action
		| IVesselOnboard_deviceErase__Action
		| IVesselOnboard_reset__Action
		| IVesselOnboard_crewDesignationSet__Action
		| IVesselOnboard_DashboardUsageAndAlertsSet__Action
		| IVesselOnboard_rangesSet__Action
		| Action<any>
): IVesselOnboardStore => {
	switch (action.type) {
		case SET_TIME_LOGS: {
			return {
				...state,
				timeLogs: (action as IVesselOnboard_timeLogsSet__Action).payload.timeLogs
			};
		}
		case APPEND_TIME_LOGS: {
			return {
				...state,
				timeLogs: [...state.timeLogs, ...(action as IVesselOnboard_timeLogsAppend__Action).payload.timeLogs]
			};
		}
		case WIZARD_DASHBOARD_USAGE_ALERTS_SET: {
			const dashboardUsageAndAlerts = (
				action as IVesselOnboard_DashboardUsageAndAlertsSet__Action
			).payload;
			return {
				...state,
				dashboardUsageAndAlerts: {
					...dashboardUsageAndAlerts.usabilityAndAlerts,
				},
			};
		}
		case WIZARD_VESSEL_RANGES_SET: {
			const { vesselRanges } = (action as IVesselOnboard_rangesSet__Action).payload;
			return { ...state, ranges: vesselRanges };
		}
		case WIZARD_VESSEL_CONFIG_LIST_SET: {
			const { logs } = (action as IVesselOnboard_configLogsListSet__Action).payload;
			return { ...state, configLogsList: logs };
		}
		case WIZARD_VESSEL_CONFIG_SET: {
			const { configInfo } = (action as IVesselOnboard_configInfoSet__Action).payload;
			return { ...state, configInfo };
		}
		case WIZARD_CREW_LIST_SET: {
			const crews = (action as IVesselOnboard_crewListSet__Action).payload;
			return { ...state, crews };
		}
		case WIZARD_DEVICE_LIST_SET: {
			const devices = (action as IVesselOnboard_deviceListSet__Action).payload;
			return { ...state, devices };
		}
		case WIZARD_CREW_NEW: {
			const { crew } = (action as IVesselOnboard_crewNew__Action).payload;
			return {
				...state,
				crews: {
					...state.crews,
					byIds: {
						...state.crews.byIds,
						[crew.id]: crew,
					},
					ids: [...state.crews.ids, crew.id],
				},
			};
		}
		case WIZARD_CREW_SET: {
			const { crew } = (action as IVesselOnboard_crewSet__Action).payload;
			return {
				...state,
				crews: {
					...state.crews,
					byIds: {
						...state.crews.byIds,
						[crew.id]: crew,
					},
				},
			};
		}
		case WIZARD_CREW_DESIGNATIONS_SET: {
			const { designations } = (
				action as IVesselOnboard_crewDesignationSet__Action
			).payload;
			return { ...state, crewDesignations: designations };
		}
		case WIZARD_CREW_EDIT: {
			const { crew } = (action as IVesselOnboard_crewEdit__Action).payload;
			return {
				...state,
				crews: {
					...state.crews,
					byIds: {
						...state.crews.byIds,
						[crew.id]: {
							...state.crews.byIds[crew.id],
							...crew,
						},
					},
				},
			};
		}
		case WIZARD_CREW_ERASE: {
			const { crewId } = (action as IVesselOnboard_crewErase__Action).payload;
			delete state.crews.byIds[crewId];
			return {
				...state,
				crews: {
					...state.crews,
					ids: state.crews.ids.filter((id) => id !== crewId),
				},
			};
		}
		case WIZARD_DEVICE_NEW: {
			const { device } = (action as IVesselOnboard_deviceNew__Action).payload;
			return {
				...state,
				devices: {
					...state.devices,
					byIds: {
						...state.devices.byIds,
						[device.id]: device,
					},
					ids: [...state.devices.ids, device.id],
				},
			};
		}
		case WIZARD_DEVICE_SET: {
			const { device } = (action as IVesselOnboard_deviceSet__Action).payload;
			return {
				...state,
				devices: {
					...state.devices,
					byIds: {
						[device.id]: device,
					},
				},
			};
		}
		case WIZARD_DEVICE_EDIT: {
			const { device } = (action as IVesselOnboard_deviceEdit__Action).payload;
			return {
				...state,
				devices: {
					...state.devices,
					byIds: {
						...state.devices.byIds,
						[device.id]: {
							...state.devices.byIds[device.id],
							...device,
						},
					},
				},
			};
		}
		case WIZARD_DEVICE_ERASE: {
			const { deviceId } = (action as IVesselOnboard_deviceErase__Action)
				.payload;
			delete state.devices.byIds[deviceId];
			return {
				...state,
				devices: {
					...state.devices,
					ids: state.devices.ids.filter((id) => id !== deviceId),
				},
			};
		}
		case WIZARD_VESSEL_ID_SET: {
			const { vesselId } = (action as any).payload;
			return {
				...state,
				vesselId,
			};
		}
		case TANKS_SET: {
			const tanks = (action as any).payload.tanks as TMARPOLTank[];
			return {
				...state,
				tanks,
			};
		}
		case WIZARD_RESET: {
			return reducerState;
		}
	}
	return state;
};

export default withForms(
	"@vessel/onboard",
	reducerState,
	withJobs("@vessel/onboard", reducerState, vesselOnboardReducers)
);
