import React, { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import { Tab, Tabs } from "@blueprintjs/core";
import { TAB_IDS } from "redux/app/vessel/constants";
import { vesselActions, vesselSelectors } from "redux/app/vessel";
import { historyActions } from "redux/_core/history";
import { rootRoutes } from "routes";
import VesselCard from "./VesselCard";
import { IListObjectStore } from "redux/_common/type";
import {
	IVessel,
	IVesselOperation,
	TVesselCardOperation,
} from "redux/app/vessel/types";
import { commonUtils } from "redux/_common";
import HomeFleetNavigation from "./HomeFleetNavigation";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import useCheckMobileScreen from "components/app/feedback/utils";
import topNavbarSelectors from "redux/app/topnavbar/selectors";
import VesselsCard from "./VesselsCard";
import VesselsTable from "./VesselsTable";
const { vesselListSelectors } = vesselSelectors;

export enum EVesselView {
	CARD = "CARD",
	TABLE = "TABLE",
}

type OwnProps = {
	triggerAddVesselOperation: () => void;
	resetOperations: () => void;
	vesselOperation: IVesselOperation;
};
type HomeProps = PropsFromRedux & OwnProps;
function Home(props: HomeProps) {
	const {
		selectedTabId,
		vessels,
		myVessels,
		favoritedVesselIds,
		currentSelectedTab,
		triggerAddVesselOperation,
		resetOperations,
		vesselOperation,
		selectedFleetId,
		isSelectionEnabled,
		selectedVesselsOnSelectionEnabled,
		jobToLoadVesselsNew,
		searchText,
	} = props;

	const isMobile = useCheckMobileScreen();
	const [vesselView, setVesselView] = useState(EVesselView.CARD);

	const saveFavorite = useCallback(
		commonUtils.debounce(props.handleFavorite, 2000),
		[]
	);

	useEffect(() => {
		if (selectedTabId === TAB_IDS.myVessels) {
			props.loadMyVessels();
		} else {
			props.loadAllVessels();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTabId, selectedFleetId]);

	useEffect(() => {
		if (selectedTabId === TAB_IDS.all) {
			props.loadFleets();
		} else {
			props.enableVesselSelection(false);
			props.resetEnableSelectedMode();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTabId]);

	const tabsRenderer = (
		<div className="px-2 m-2 mb-0 bg-white rounded-md shadow-sm">
			<div className="ml-2">
				<Tabs
					id="vessel-tabs"
					animate={true}
					renderActiveTabPanelOnly={true}
					vertical={false}
					large={true}
					selectedTabId={selectedTabId}
					className="w-full rounded-sm"
					onChange={function onTabsChange(tabId) {
						props.handleTabChange(tabId as TAB_IDS);
					}}
				>
					<Tab
						id={TAB_IDS.myVessels}
						title="My Vessels"
						disabled={false}
						className="font-medium text-gray-600 px-2 py-1"
					></Tab>
					<Tab
						id={TAB_IDS.all}
						title="All"
						disabled={false}
						className="font-medium text-gray-600 px-2 py-1"
					></Tab>
					<Tabs.Expander />
				</Tabs>
			</div>
		</div>
	);

	let vesselsToShow = selectedTabId === TAB_IDS.myVessels ? myVessels : vessels;

	if (searchText) {
		const searchTextBasedFilteredIds = vesselsToShow.ids.filter(
			(vesselId) => {
				const vessel = vesselsToShow.byIds[vesselId];
				return vessel.vesselName
					.toLowerCase()
					.includes(searchText.toLowerCase());
			}
		);
		vesselsToShow = {
			ids: searchTextBasedFilteredIds,
			byIds: vesselsToShow.byIds,
		};
	}

	if (jobToLoadVesselsNew.isLoading) {
		return (
			<div className="h-full w-full">
				{tabsRenderer}

				<div className=" bg-white h-[calc(100vh-78px)]  m-2 rounded-md overflow-y-auto">
					{selectedTabId === TAB_IDS.all && (
						<div className="flex justify-between items-center m-2 px-8 ">
							<div className=" flex justify-center w-full md:block">
								<Skeleton width={isMobile ? 290 : 350} height={50} />
							</div>
							<div className="hidden md:block">
								<Skeleton width={120} height={50} />
							</div>
						</div>
					)}
					<div
						className={classNames(
							"flex  justify-center items-center  flex-wrap",
							{
								"mt-4": selectedTabId === TAB_IDS.myVessels,
							}
						)}
					>
						{Array.from({ length: 16 }, (_, index) => {
							return index;
						}).map((val, index) => {
							return (
								<Skeleton
									key={index}
									className="rounded-lg m-3"
									width={297}
									height={161}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}

	if (vesselsToShow?.ids && !vesselsToShow?.ids?.length) {
		return (
			<>
				{tabsRenderer}
				<div className="px-2 m-2 bg-white rounded-md h-[calc(100vh-78px)]">
					{TAB_IDS.all === selectedTabId && (
						<div className="sticky top-0 bg-white">
							<HomeFleetNavigation
								vesselView={vesselView}
								onVesselViewChange={(vesselView) => setVesselView(vesselView)}
								triggerAddVesselOperation={triggerAddVesselOperation}
								resetOperations={resetOperations}
								vesselOperation={vesselOperation}
							/>
						</div>
					)}
					<div className="h-full w-full flex justify-center items-center">
						<p className="text-center text-xl">No Vessels found</p>
					</div>
				</div>
			</>
		);
	}

	return (
		<div>
			{tabsRenderer}
			<div className="px-2 m-2  mb-6 bg-white rounded-md h-[calc(100vh-78px)] overflow-y-auto">
				{TAB_IDS.all === selectedTabId? (
					<div className="sticky top-0 bg-white">
						<HomeFleetNavigation
							vesselView={vesselView}
							onVesselViewChange={(vesselView) => setVesselView(vesselView)}
							triggerAddVesselOperation={triggerAddVesselOperation}
							resetOperations={resetOperations}
							vesselOperation={vesselOperation}
						/>
					</div>
				): (
					<>
						<div className="flex flex-col md:flex-row justify-between items-center border-b py-3 mx-2">
							<div
								className="flex items-center gap-x-2"
							>
								<button
									onClick={() => {
										setVesselView(EVesselView.CARD);
									}}
									className={classNames("flex items-center justify-center border-none outline-none p-2 rounded-md", {
										"bg-[#EEEFFF]": vesselView === EVesselView.CARD,
										"bg-transparent": vesselView !== EVesselView.CARD,
									})}
								>
									<span
										className="text-lg bp3-icon bp3-icon-grid-view"
									/>
								</button>
								<button
									onClick={() => {
										setVesselView(EVesselView.TABLE);
									}}
									className={classNames("flex items-center justify-center border-none outline-none p-2 rounded-md", {
										"bg-[#EEEFFF]": vesselView === EVesselView.TABLE,
										"bg-transparent": vesselView !== EVesselView.TABLE,
									})}
								>
									<span
										className="text-lg bp3-icon bp3-icon-th"
									/>
								</button>
							</div>
						</div>
					</>
				)}
				<div
					className={classNames("flex justify-center items-center mt-4", {
						"pt-4": selectedTabId === TAB_IDS.myVessels,
					})}
				>
					{
						vesselView === EVesselView.TABLE ? (
							<VesselsTable
								currentSelectedTab={currentSelectedTab}
								vesselsToShow={vesselsToShow}
								isSelectionEnabled={isSelectionEnabled}
								selectedVesselsOnSelectionEnabled={selectedVesselsOnSelectionEnabled}
								favoritedVesselIds={favoritedVesselIds}
								saveFavorite={saveFavorite}
								onOnboardClick={props.onOnboardClick}
								selectVesselAndAdd={props.selectVesselAndAdd}
								favoriteAVessel={props.favoriteAVessel}
								updateMyVesselList={props.updateMyVesselList}
								vesselCardOperationAdd={props.vesselCardOperationAdd}
							/>
						): (
							<VesselsCard
								currentSelectedTab={currentSelectedTab}
								vesselsToShow={vesselsToShow}
								isSelectionEnabled={isSelectionEnabled}
								selectedVesselsOnSelectionEnabled={selectedVesselsOnSelectionEnabled}
								favoritedVesselIds={favoritedVesselIds}
								saveFavorite={saveFavorite}
								onOnboardClick={props.onOnboardClick}
								selectVesselAndAdd={props.selectVesselAndAdd}
								favoriteAVessel={props.favoriteAVessel}
								updateMyVesselList={props.updateMyVesselList}
								vesselCardOperationAdd={props.vesselCardOperationAdd}
							/>
						)
					}
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const vesselStore = store.app.vesselStore;
	const { vessels } = vesselListSelectors(vesselStore).getVesselListData();
	const topNavbarStore = topNavbarSelectors._getStore(store);
	const favoritedVesselIds = vesselStore.favoriteVesselIds;
	const myVessels = vesselStore.myVessels;
	return {
		vessels,
		selectedTabId: vesselSelectors.getSelectedTab(vesselStore),
		favoritedVesselIds,
		currentSelectedTab: vesselStore?.selectedTab,
		myVessels,
		selectedFleetId: vesselStore?.selectedFleetId,
		isSelectionEnabled: vesselStore?.enableSelection,
		selectedVesselsOnSelectionEnabled:
			vesselStore?.selectedVesselsOnEnableSelection,
		jobToLoadVesselsNew: vesselSelectors.jobToLoadVesselsNew(vesselStore),
		searchText: topNavbarStore.searchText,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadAllVessels() {
			dispatch(vesselActions.listLoadNew());
		},
		loadMyVessels() {
			dispatch(vesselActions.listMyVessels());
		},
		onOnboardClick(vesselId: string) {
			dispatch(
				historyActions.push(
					"#VesselList",
					rootRoutes.children.app.children.vessel.children.onboard.children.dashboard.url(
						vesselId
					)
				)
			);
		},
		updateMyVesselList(updatedVessels: IListObjectStore<IVessel>) {
			dispatch(vesselActions.myVesselsListSet(updatedVessels));
		},
		handleTabChange(tabSelected: TAB_IDS) {
			dispatch(vesselActions.tabSelect(tabSelected));
		},
		// state update
		favoriteAVessel(favoritedVesselIds: string[]) {
			dispatch(vesselActions.favoritedVessels(favoritedVesselIds));
		},
		handleFavorite(favoritedVesselIds: string[]) {
			dispatch(vesselActions.favoriteAVessel(favoritedVesselIds));
		},
		loadFleets() {
			dispatch(vesselActions.fleetListLoad());
		},
		selectVesselAndAdd(vesselId: string) {
			dispatch(vesselActions.addVesselToSelectedListOnEnable(vesselId));
		},
		enableVesselSelection(isSelected: boolean) {
			dispatch(vesselActions.enableVesselSelection(isSelected));
		},
		resetEnableSelectedMode() {
			dispatch(vesselActions.selectedVesselsReset());
		},
		vesselCardOperationAdd(operation: TVesselCardOperation) {
			dispatch(vesselActions.priorToDebounceOperationAdd(operation));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Home);

/**
 * 
 * When user is in All tabs section - he favorites/unfavorites and quickly moves to MyVessels tab then we ensure state is updated through the below code.
 * But the problem is since updation is time consuming, though state got changed but since the api call to my-vessels happen prior to completion of operation of favorite
 * the state gets modified with the api data which is yet again stale.

if(currentSelectedTab === TAB_IDS.all){
	// INFO: on click of favorite we also need to ensure my-vessels tab state is also updated as current api is slow
	
	let updatedMyVessels = {
		ids: [...myVessels.ids],
		byIds: {
			...myVessels.byIds
		}
	}
	// clicking on favorited card
	if(favoritedVesselIds.includes(vesselIdToFavorite)){
		delete updatedMyVessels.byIds[vesselIdToFavorite];
		updatedMyVessels = {
			ids: updatedMyVessels.ids.filter(vesselId => vesselId !== vesselIdToFavorite ),
			byIds: updatedMyVessels.byIds
		}
	} else {
	// clicking on normal vessel card
		updatedMyVessels = {
			ids:[...updatedMyVessels.ids, vesselIdToFavorite],
			byIds: {
				...updatedMyVessels.byIds,
				[vesselIdToFavorite]: vessels.byIds[vesselIdToFavorite]
			}
		}

	}
	props.updateMyVesselList(updatedMyVessels);
}
 */
