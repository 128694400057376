import classNames from 'classnames';
import InfiniteProgress from 'components/_common/InfiniteProgress';
import Pagination from "components/_common/NumberedPagination";
import React, { FC, useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { historyActions } from 'redux/_core/history';
import { IStore } from 'redux/store';
import { rootRoutes } from 'routes';
import ModalPortal from 'components/app/_common/ModalPortal';
import { ITabReport } from 'redux/app/logs/@types';
import { logActions, logSelectors } from 'redux/app/logs';
import ReportRow from './ReportRow';
import TemplateRenderer from './TemplateRenderer';

type ReportsOwnProps = {
	pageNumber: string;
	vesselId?: string;
};

type ReportsProps = PropsFromRedux & ReportsOwnProps;

const Reports: FC<ReportsProps> = (props) => {
	const {
		reports,
		paginationOfReports,
		pageNumber,
		jobToLoadReports,
		jobToFilterReports,
		vesselId,
	} = props;
	const setIsDragRef = useRef((value: boolean) => {});

	const [reportOperation, setReportOperation] = useState<{
		isModalOpen: boolean;
	}>({
		isModalOpen: false
	});

	function resetOperations() {
		setReportOperation((reportOperation) => {
			return {
				...reportOperation,
				isModalOpen: false,
			};
		});
	}
	
	useEffect(() => {
		// INFO: when user selects a new vessel in the list will run again
		props.loadReportCount(vesselId || "");
		props.loadReports(paginationOfReports.currentPage !== +pageNumber ? +pageNumber : paginationOfReports.currentPage, true, vesselId || "")

		return () => {
			props.resetPageNumber();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[vesselId,paginationOfReports.currentPage,pageNumber]);

	function selectReport(report: ITabReport | null) {
		if (report) {
			setReportOperation((reportOperation) => {
				return {
					...reportOperation,
					isModalOpen: true
				};
			})
			props.loadReport(report, vesselId || "");
		}
	}

	return (
		<div className="min-h-full  py-6 pt-0 mb-6 bg-white rounded-md">
			<div
				className={classNames(
					"flex flex-row items-end justify-between  my-6 "
				)}
			>
				<div>
					<h3 className="text-2xl font-medium text-gray-700 font-redhat-display">
						Reports
					</h3>
					<h6 className="text-sm text-gray-600">
						{jobToLoadReports.isLoading
							? "Loading reports please wait"
							: ""}
					</h6>
				</div>
			</div>
			<div className="mb-6 overflow-x-auto">
				<InfiniteProgress
					isLoading={jobToLoadReports.isLoading || jobToFilterReports.isLoading}
					isSpacedOut={false}
				/>
				<table
					className={classNames(
						"w-full relative mb-6  border border-gray-300 rounded-sm bp3-html-table bp3-interactive",
						{ "animate-pulse": jobToLoadReports.isLoading }
					)}
				>
					<thead className="relative">
						<tr>
							<th className="text-sm text-gray-500">No.</th>
							<th className="text-sm text-gray-500">Title</th>
							{/* <th className="text-sm text-gray-500">Vessel</th> */}
							<th className="text-sm text-center text-gray-500">
								Created Date
							</th>
							<th className="text-sm text-center text-gray-500">
								Selected Date
							</th>
							<th className="text-sm text-center text-gray-500">
								Status
							</th>
							<th className="text-sm text-center text-gray-500">
								Actions
							</th>
						</tr>
						{/* <LogFilterHeader /> */}
					</thead>
					<tbody
						className={classNames({
							"h-0 opacity-0 transition-all duration-100 max-h-0":
								jobToLoadReports.isLoading && reports?.length === 0,
							"h-auto opacity-100 transition-all duration-1000 delay-300 max-h-auto":
								!jobToLoadReports.isLoading,
						})}
					>
						{reports?.map((report, index) => {
							return (
								<ReportRow
									report={report}
									key={index}
									onClick={(report: ITabReport) => {
										selectReport(report);
									}}
									indexNumber={
										(paginationOfReports.currentPage - 1) *
											paginationOfReports.itemsPerPage +
										(index + 1)
									}
								/>
							);
						})}
					</tbody>
				</table>
				{!jobToLoadReports.isLoading && reports?.length === 0 ? (
					<div className="flex flex-col justify-center h-64 text-center bg-gray-100">
						<h5 className="text-2xl font-thin text-gray-500 font-redhat-display">
							No Reports found..
						</h5>
					</div>
				) : null}
				<div></div>
					<Pagination
						{...paginationOfReports}
						onPageChange={function onPageChange(
							pageNumberFromPagination
						) {
							props.goToNewPage(vesselId || "", pageNumberFromPagination);
						}}
						isPageLoading={
							jobToLoadReports.isLoading || jobToFilterReports.isLoading
						}
					/>
				
			</div>
				<ModalPortal
					isActive={reportOperation.isModalOpen}
					onClose={() => {
						resetOperations();
					}}
					isFullWidth
				>
					{reportOperation.isModalOpen ? (
						<TemplateRenderer
							setIsDragRef={setIsDragRef}
							onClose={function onClose() {
								props.clearSelectedReport();
								resetOperations();
							}}
						/>
					) : null}
				</ModalPortal>
			</div>
			
	);
}

function mapStateToProps(store: IStore) {
	const reportStore = store.app.logs;
	const reports = logSelectors.getReports(reportStore);

	return {
		reports: reports,
		paginationOfReports: logSelectors.getReportsPagination(reportStore),
		jobToLoadReports: logSelectors.jobToLoadReports(reportStore),
		jobToFilterReports: logSelectors.jobToLoadPrintTemplates(reportStore),
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadReportCount(
			vesselId: string
		) {
			dispatch(
				logActions.commands.reportsCountLoad(
					vesselId
				)
			);
		},
		loadReports(
			pageNumber: number,
			showPageNumberInNotification: boolean = false,
			vesselId: string
		) {
			dispatch(
				logActions.commands.reportsLoad({
					pageNumber,
					showPageNumberInNotification,
					vesselId
				})
			);
		},
		loadReport(report: ITabReport, vesselId: string) {
			dispatch(logActions.commands.singleReportLoad(report, vesselId));
		},

		clearSelectedReport() {
			dispatch(logActions.document.clearSelectedReport());
		},
		goToNewPage(vesselId: string,pageNumber: number) {
			dispatch(
				historyActions.push(
					"#Report",
					rootRoutes.children.app.children.vessel.children.onboard.children.reports.url(
						vesselId,pageNumber
					)
				)
			);
		},
		resetPageNumber() {
			dispatch(logActions.document.reportsPaginationCurrentPageSet(1));
		}
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Reports);