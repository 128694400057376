import React, { useEffect, useState } from "react";
import { withRouter, RouteProps } from "react-router-dom";
import { Tag, Tabs, Tab, PopoverInteractionKind } from "@blueprintjs/core";
import { rootRoutes } from "../../../../routes";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { historyActions } from "../../../../redux/_core/history";
import { IStore } from "../../../../redux/store";
import {
	vesselOnboardActions,
	vesselOnboardSelectors,
} from "../../../../redux/app/vessel/onboard";
import { vesselSelectors } from "../../../../redux/app/vessel";
import ReactJson from "react-json-view";
import SVGVessel from "../../../_common/icons/SVGVessel";
import Popover from "../../../../@set-product/core/Popover";
import ConfirmBox from "../../../_common/ConfirmBox";
import VesselOnboardExplainerBox from "./_elements/OnboardExplainerBox";
const { crew, device, tanks, view, logs, checklist, reports, marpol, dashboard, info, ranges } =
	rootRoutes.children.app.children.vessel.children.onboard.children;
type OwnProps = {
	match: {
		params: {
			vesselId: string;
			pageNumber?: string;
		};
	};
};
type VesselOnboardProps = React.PropsWithChildren<
	RouteProps & OwnProps & PropsFromRedux
>;
function VesselOnboard(props: VesselOnboardProps) {
	const {
		location,
		children,
		match
	} = props;
	const vesselId = match.params.vesselId;
	const pageNumber = match.params.pageNumber ? +match.params.pageNumber : 1;


	useEffect(
		function onMount() {
			props.setVesselToConfigure(vesselId);
			props.loadVessel(vesselId);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const tabIds = {
		crew: "crew-tab-id",
		devices: "devices-tab-id",
		tanks: "tanks-tab-id",
		logs: "logs-tab-id",
		checklist: "checklist-tab-id",
		reports: "reports-tab-id",
		marpol: "marpol-tab-id",
		dashboard: "dashboard-tab-id",
		info: "info-tab-id",
		ranges: "ranges-tab-id",
	};
	const isCrewRouteActive = location?.pathname.includes("/crew");
	const isDeviceRouteActive = location?.pathname.includes("/device");
	const isTanksRouteActive = location?.pathname.includes("/tanks");
	const isLogsRouteActive = location?.pathname.includes("/logs");
	const isChecklistRouteActive = location?.pathname.includes("/checklist");
	const isReportsRouteActive = location?.pathname.includes("/reports");
	const isMarpolRouteActive = location?.pathname.includes("/marpol");
	const isDashboardRouteActive = location?.pathname.includes("/dashboard");
	const isInfoRouteActive = location?.pathname.includes("/info");
	const isRangeRouteActive = location?.pathname.includes("/ranges");

	const [activeTabId, setActiveTabId] = useState(getActiveTab());

	function getActiveTab() {
		if (isDashboardRouteActive) return tabIds.dashboard;
		if (isCrewRouteActive) return tabIds.crew;
		if (isDeviceRouteActive) return tabIds.devices;
		if (isTanksRouteActive) return tabIds.tanks;
		if (isLogsRouteActive) return tabIds.logs;
		if (isChecklistRouteActive) return tabIds.checklist;
		if (isReportsRouteActive) return tabIds.reports;
		if (isMarpolRouteActive) return tabIds.marpol;
		if (isInfoRouteActive) return tabIds.info;
		if (isRangeRouteActive) return tabIds.ranges;
	}

	function handleTabChange(tabId: string) {
		if (tabId === tabIds.dashboard) props.onTabChange(dashboard.url(vesselId));
		if (tabId === tabIds.crew) props.onTabChange(crew.url(vesselId));
		if (tabId === tabIds.devices) props.onTabChange(device.url(vesselId));
		if (tabId === tabIds.tanks) props.onTabChange(tanks.url(vesselId));
		if (tabId === tabIds.logs)
			props.onTabChange(logs.url(vesselId, pageNumber));
		if (tabId === tabIds.checklist)
			props.onTabChange(checklist.url(vesselId, pageNumber));
		if (tabId === tabIds.reports)
			props.onTabChange(reports.url(vesselId, pageNumber));
		setActiveTabId(tabId);
		if (tabId === tabIds.marpol)
			props.onTabChange(marpol.url(vesselId, pageNumber));
		if (tabId === tabIds.info) props.onTabChange(info.url(vesselId));
		if (tabId === tabIds.ranges) props.onTabChange(ranges.url(vesselId));
	}

	return (
		<div>
			<div className="my-2 md:m-2 md:px-4 bg-white rounded-md">
				<div className="mx-2">
					<Tabs
						id="onboard-tabs"
						animate={true}
						renderActiveTabPanelOnly={true}
						vertical={false}
						large={true}
						selectedTabId={activeTabId}
						className="w-full rounded-sm bp3-html-table bp3-interactive overflow-x-auto"
						onChange={function onTabsChange(tabId) {
							handleTabChange(tabId as string);
						}}
					>
						<Tab
							id={tabIds.dashboard}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-dashboard" />
							}
							disabled={false}
							className="font-medium text-gray-600 "
						>
							Dashboard
						</Tab>
						<Tab
							id={tabIds.crew}
							title={<span className="mr-3 text-lg bp3-icon bp3-icon-person" />}
							disabled={false}
							className="font-medium text-gray-600 "
						>
							Crew
						</Tab>
						<Tab
							id={tabIds.devices}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-mobile-phone" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Devices
						</Tab>
						<Tab
							id={tabIds.tanks}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-database" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Tanks
						</Tab>
						<Tab
							id={tabIds.logs}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-clipboard" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Logs
						</Tab>
						<Tab
							id={tabIds.checklist}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-form" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Checklists
						</Tab>
						<Tab
							id={tabIds.reports}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-manual" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Reports
						</Tab>
						<Tab
							id={tabIds.marpol}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-application" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Marpol
						</Tab>
						<Tab
							id={tabIds.info}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-info-sign" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Info
						</Tab>
						<Tab
							id={tabIds.ranges}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-numbered-list" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Ranges
						</Tab>
						<Tabs.Expander />
					</Tabs>
					<div className="border-t border-gray-300 pb-2" />
				</div>
			</div>
			<div className="min-h-full md:m-2 md:px-6 pb-6 pt-3 mb-6 bg-white rounded-md">
				{/* <div className="flex flex-row items-end justify-between h-16  "> */}
				{/* <div>
						<h3 className="text-2xl font-light text-gray-600 align-baseline select-none font-redhat-display flex items-center">
							<span
								title="go back"
								onClick={() => props.goBackToHome()}
								className="bp3-icon bp3-icon-arrow-left text-lg mr-3 cursor-pointer"
							/>
							<span className="hidden md:inline-block">
								<SVGVessel className="w-6 h-6 -mb-1" />{" "}
							</span>
							<span className="inline-block mx-2">
								{jobToLoadVessel.isLoading ? (
									"Loading Vessel"
								) : (
									<>
										<span className="text-gray-400" title="Prefix">
											{vessel?.vesselPrefix} -
										</span>{" "}
										<span title="Vessel Name">{vessel?.vesselName}</span>
									</>
								)}
							</span>
						</h3>
					</div> */}

				{/* <div className="flex flex-row items-baseline justify-center -mx-1 -mb-2">
					<div className="">
						{jobToLoadVessel.isLoading ? (
							<span className="font-medium text-gray-400">Loading</span>
						) : (
							<Popover
								interactionKind={PopoverInteractionKind.HOVER}
								className="rounded-lg"
								content={<VesselOnboardExplainerBox />}
								target={
									<>
										{isVesselOnboarded ? (
											<Tag
												large={true}
												className="font-thin text-gray-600 bg-gray-300 rounded-full cursor-help"
												rightIcon={"tick-circle"}
											>
												Onboarded
											</Tag>
										) : (
											<Tag
												large={true}
												className="font-thin text-white bg-red-400 rounded-full cursor-help"
												rightIcon={"help"}
											>
												Not onboarded
											</Tag>
										)}
									</>
								}
							/>
						)}
					</div>
				</div> */}
				{/* </div> */}
				<div className=" rounded-md rounded-t-none">{children}</div>

				<div className="footer" />
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const vesselStore = vesselSelectors._getStore(store);
	const onboardStore = vesselOnboardSelectors._getStore(store);
	const vessel = vesselSelectors.getVesselById(
		ownProps.match.params.vesselId,
		vesselSelectors.getVessels(vesselStore)
	);
	const storeTenantId = store.app.auth.user?.tenantId;
	return {
		vessel,
		jobToLoadVessel: vesselOnboardSelectors.jobToLoadVessel(onboardStore),
		isVesselOnboarded: vesselSelectors
			.vesselItemSelectors(vessel)
			.isOnboarded(),
		storeTenantId,
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		onTabChange(routeUrl: string) {
			dispatch(historyActions.push("#VesselOnboard", routeUrl));
		},
		setVesselToConfigure(vesselId: string) {
			dispatch(vesselOnboardActions.document.setVesselId(vesselId));
		},
		loadVessel(vesselId: string) {
			dispatch(vesselOnboardActions.command.vesselLoad(vesselId, false));
		},
		
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default withRouter(reduxConnector(VesselOnboard));