import React, { useEffect } from "react";
import { IStore } from "../../../../redux/store";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { authActions, authSelectors } from "redux/app/auth";
import Map from "./Map";
import { vesselActions, vesselSelectors } from "redux/app/vessel";
import { TAB_IDS } from "redux/app/vessel/constants";
import DashboardNavigation from "./_elements/DashboardNavigation";
import dashboardSelectors from "redux/app/dashboard/selectors";
import Table from "./_elements/Table";
import { IVessel } from "redux/app/vessel/types";
import dashboardActions from "redux/app/dashboard/actions";
import { getVesselLocations } from "redux/app/dashboard/utils";
import { IListObjectStore } from "redux/_common/type";

type DashboardListProps = {} & PropsFromRedux;
function DashboardList(props: DashboardListProps) {
	const { viewType, vessels, tenantId } = props;
	const [selectedShip, setSelectedShip] = React.useState(null as any);
	const [showShipDetails, setShowShipDetails] = React.useState(false);

	useEffect(() => {
		if (tenantId) {
			props.loadTenantInfo(tenantId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenantId]);

	useEffect(() => {
		props.setSelectedTabToAllVessels();
		props.setIsDashboardPage(true);
		return () => {
			props.setIsDashboardPage(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// INFO: created this to add any other view in future
	const isMapView = viewType === "map";
	const isTableView = viewType === "table";

	return (
		<>
			<div className="h-[calc(100vh-16px)]  bg-white rounded-md  m-2">
				<DashboardNavigation />
				{false && (
					<div className="flex justify-center pb-2">
						<div className="flex flex-col items-center justify-center">
							<h4 className="my-3 mt-2 text-sm font-medium">
								Normal
							</h4>
							<div className="flex items-center justify-center ">
								{isMapView && (
									<div className="flex flex-col items-center justify-center w-16 text-center">
										<div className="w-11 h-4 bg-[#459FFF]" />
										<p className="text-[#444444] text-[12px] ">
											All Good
										</p>
									</div>
								)}
								<div className="flex flex-col items-center justify-center w-16 text-center">
									<div className="w-11 h-4 bg-[#BCBCBC]" />
									<p className="text-[#444444] text-[12px] ">
										Offline
									</p>
								</div>
							</div>
						</div>
						<div className="flex ml-4">
							<div className="flex flex-col items-center justify-center">
								<h4 className="my-3 mt-2 text-sm font-medium">
									Caution
								</h4>
								<div className="flex items-center justify-center ">
									<div className="flex flex-col items-center justify-center w-16 text-center">
										<div className="w-11 h-4 bg-[#E67E22]" />
										<p className="text-[#444444] text-[12px] ">
											High
										</p>
									</div>
									<div className="flex flex-col items-center justify-center w-16 text-center">
										<div className="w-11 h-4 bg-[#DCD413]" />
										<p className="text-[#444444] text-[12px] ">
											Medium
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				<div className="w-full relative z-0 rounded-b-md h-full md:h-[calc(100vh-78px)] overflow-y-auto">
					{isMapView && (
						<Map
							selectedShip={selectedShip}
							setSelectedShip={setSelectedShip}
							setShowShipDetails={setShowShipDetails}
							vesselLocations={props.vesselLocations}
						/>
					)}
					{isTableView && <Table vessels={vessels} />}
				</div>
			</div>
		</>
	);
}

function mapStateToProps(store: IStore) {
	const isLoggedIn = authSelectors(store.app.auth).isLoggedIn();

	const vesselStore = store.app.vesselStore;
	const selectedTabId = vesselSelectors.getSelectedTab(vesselStore);

	let vessels: IListObjectStore<IVessel>;

	const { vessels: allVessels } = vesselSelectors
		.vesselListSelectors(vesselStore)
		.getVesselListData();
	if (selectedTabId === "all") {
		vessels = allVessels;
	} else {
		vessels = vesselSelectors.getMyVessels(vesselStore);
	}

	const vesselLocations = getVesselLocations(vessels);

	const dashboardStore = dashboardSelectors._getStore(store);
	const viewType = dashboardSelectors.getViewType(dashboardStore);
	const tenantId = store.app.auth?.user?.tenantId;

	return {
		isLoggedIn,
		vessels,
		vesselLocations: vesselLocations,
		viewType,
		tenantId
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		setSelectedTabToAllVessels() {
			dispatch(vesselActions.tabSelect(TAB_IDS.all));
		},
		// loadAllVessels() {
		// 	dispatch(vesselActions.tabSelect(TAB_IDS.all));
		// 	return dispatch(vesselActions.listLoadNew());
		// },
		setIsDashboardPage(isDashboard: boolean) {
			dispatch(dashboardActions.document.isDashboardPageSet(isDashboard));
		},
		loadTenantInfo(tenantId: string) {
			dispatch(authActions.tenantInfoLoad(tenantId));
		},
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(DashboardList);
