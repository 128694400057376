import classNames from "classnames";
import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import authSelectors from "../../../../../redux/app/auth/selectors";
import { IGarbageRecord } from "../../../../../redux/app/garbage-record/@types";
import garbageRecordHelper from "../../../../../redux/app/garbage-record/helper";
import { IStore } from "../../../../../redux/store";

type OwnProps = {
	garbageRecordForView: IGarbageRecord;
};
type GarbageRecordEditsForPrintProps = PropsFromRedux & OwnProps;
function GarbageRecordEditsForPrint(props: GarbageRecordEditsForPrintProps) {
	const { editHistory } = props;
	return (
		<div className="p-3">
			<div className="sticky -mt-2 -top-6">
				<h6
					className={classNames(
						" text-xs font-medium text-gray-700 bg-white rounded-t-md flex flex-row justify-between items-baseline",
						{
							"py-2": editHistory.length > 0,
							"pt-2": editHistory.length === 0,
						}
					)}
				>
					<span>Edit History </span>
					<span className="font-normal text-gray-600">
						{editHistory.length > 0 ? `${editHistory.length} time Edits` : null}
					</span>
				</h6>
			</div>
			{editHistory.length === 0 ? (
				<h4 className="text-sm font-medium">No edits have been made so far</h4>
			) : (
				<ul>
					{editHistory.map((editItem) => (
						<li key={editItem.fieldName}>
							{editItem.edits.map((edit) => {
								return (
									<div
										key={edit.editSessionNumber}
										className="mb-3 text-sm leading-5 tracking-wide"
									>
										<>
											<span className="font-medium text-gray-800">
												{edit.editor.name}{" "}
											</span>
											<span className="font-medium text-gray-800">
												({edit.editor.staffId})
											</span>
										</>{" "}
										on{" "}
										<span className="font-medium text-gray-800">
											{moment(edit.timeStamp).format("DD-MMM-YYYY HH:mm")}
										</span>{" "}
										changed{" "}
										<span className="font-medium text-gray-800">
											{edit.fieldLabel}
										</span>{" "}
										from{" "}
										<span className="font-medium text-gray-800">
											{edit.pastValue}
										</span>{" "}
										to{" "}
										<span className={"font-medium text-gray-800"}>
											{edit.newValue}
										</span>
									</div>
								);
							})}
						</li>
					))}
				</ul>
			)}
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {
		editHistory: garbageRecordHelper.getEditHistoryForGarbage(
			ownProps.garbageRecordForView
		),
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GarbageRecordEditsForPrint);
