import { IAPIRequestMetaData } from "../../_core/api/types";
import vesselActionTypes from "./action-types";
import { ICreateFleetResponse, IUserDetail, IVessel, IVesselFilters } from "./types";

const {
	VESSEL_LIST_REMOVE,
	VESSEL_LIST_LOAD,
	VESSEL_LIST_FILTER,
	VESSEL_LIST_LOAD_NEW,
	MY_VESSELS_LOAD,
	FAVORITE_A_VESSEL,
	FLEET_LIST_LOAD,
	FLEET_SELECTED_VESSEL_ASSIGN,
	FLEET_CREATE
} = vesselActionTypes;

const vesselAPIs = {
	listAPI(pageNumber = 1, itemsPerPage = 500): IAPIRequestMetaData<any> {
		return {
			feature: VESSEL_LIST_LOAD,
			method: "GET",
			url: "api/vessels",
			queryParams: {
				filter: {
					limit: itemsPerPage,
					skip: (pageNumber - 1) * itemsPerPage,
				},
			},
		};
	},
	getUserDetails(userId: string): IAPIRequestMetaData<IUserDetail> {
		return {
			feature: MY_VESSELS_LOAD,
			method: "GET",
			url: `/api/users/${userId}`,
		};
	},
	listVesselsNew(
		where: any = undefined,
		pageNumber = 1,
		itemsPerPage = 500
	): IAPIRequestMetaData<IVessel> {
		return {
			feature: VESSEL_LIST_LOAD_NEW,
			method: "GET",
			url: "api/vessels",
			queryParams: {
				filter: {
					where,
					limit: itemsPerPage,
					skip: (pageNumber - 1) * itemsPerPage,
				},
			},
		};
	},
	filterAPI(
		filters: IVesselFilters,
		pageNumber = 1,
		itemsPerPage = 30
	): IAPIRequestMetaData<any> {
		return {
			feature: VESSEL_LIST_FILTER,
			method: "GET",
			url: "api/vessels",
			queryParams: {
				filter: {
					limit: itemsPerPage,
					skip: (pageNumber - 1) * itemsPerPage,
					where: {
						and: [
							{
								imoNumber: {
									like: `${filters.imoNumber}%`,
								},
							},
							{
								vesselName: {
									like: `${filters.vesselName}%`,
								},
							},
							{
								vesselType: {
									like: `${filters.vesselType}%`,
								},
							},
						],
					},
				},
			},
		};
	},
	favoriteAVessel(
		favourite_vessels: string[],
		token: string,
		userId: string
	): IAPIRequestMetaData<any> {
		return {
			feature: FAVORITE_A_VESSEL,
			method: "PATCH",
			url: "https://console.wayship.io/api/favouritevessels",
			body: {
				favourite_vessels,
				token,
				userId,
			},
		};
	},
	removeAPI(vesselId: string): IAPIRequestMetaData<any> {
		return {
			feature: VESSEL_LIST_REMOVE,
			method: "DELETE",
			url: `api/vessels/${vesselId}`,
		};
	},
	listFleetsForTenant(
		token: string,
		userId: string,
		tenantId: string
	): IAPIRequestMetaData<any> {
		return {
			feature: FLEET_LIST_LOAD,
			method: "GET",
			url: "https://console.wayship.io/api/fleet?all=true",
			queryParams: {
				token,
				userId,
				tenantId,
			},
		};
	},
	assignSelectedVesselsToFleet(
		vessels: string[],
		fleetId: string,
		userId: string,
		token: string
	): IAPIRequestMetaData<any> {
		return {
			feature: FLEET_SELECTED_VESSEL_ASSIGN,
			method: "POST",
			url: "https://console.wayship.io/api/fleet?assign=true",
			body: {
				vessels,
				fleetId,
				userId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
			},
		};
	},
	createFleet(token:string,userId:string,tenantId:string,name:string):IAPIRequestMetaData<ICreateFleetResponse>{
		return {
			feature: FLEET_CREATE,
			method: "POST",
			url: "https://console.wayship.io/api/fleet",
			body: {
				token,
				userId,
				tenantId,
				name
			}
		}
	}
};

export default vesselAPIs;
