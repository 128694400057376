import { Reducer } from "redux";
import actionTypes from "./action-types";
import {
	IVesselStore,
	IVessel_ListRemove__Action,
	IVessel_ListLoad__Action,
	IVessel_ListFilter__Action,
	IVessel_ListSet__Action,
	IVessel_ListReset__Action,
	IVessel_ListPush__Action,
	IVessel_listEdit__Action,
	ITab_Select,
	IVessel_FavoriteSet__Action,
	IFleet_ListSet__Action,
	IFleet_IdSet__Action,
	IEnable_VesselSelection__Action,
	ISelectedVessel_Add__Action,
	IFleetName_Add__Action,
	IFleetDropdown_Toggle__Action,
	IVesselCard_OperationAdd__Action,
} from "./types";
import withForms, { formUtils } from "../../_core/form";
import vesselOnboardReducers from "./onboard";
import onboardReducerState from "./onboard/reducers.state";
import withJobs from "../../_core/job/reducers";
import { TAB_IDS } from "./constants";

const {
	VESSEL_LIST_REMOVE,
	VESSEL_LIST_SET,
	VESSEL_LIST_RESET,
	VESSEL_LIST_PUSH,
	VESSEL_LIST_EDIT,
	TAB_SELECT,
	FAVORITED_VESSELS_SET,
	MY_VESSELS_LIST_SET,
	FLEET_LIST_SET,
	FLEET_ID_SET,
	VESSEL_SELECTION_ENABLE,
	VESSEL_SELECTED_ADD,
	VESSEL_SELECTED_RESET,
	ASSIGN_VESSELS_FLEET_ID_SET,
	FLEET_CREATE_MODAL_TOGGLE,
	FLEET_NAME_ADD,
	FLEET_LIST_DROPDOWN_TOGGLE,
	ASSIGN_VESSEL_TO_FLEET_DROPDOWN_TOGGLE,
	VESSEL_CARD_OPERATION_ADD,
	VESSEL_CARD_OPERATIONS_RESET,
} = actionTypes;

const reducerState: IVesselStore = {
	vessels: {
		byIds: {},
		ids: [],
	},
	myVessels: {
		byIds: {},
		ids: [],
	},
	_forms: {
		FILTER: formUtils.getDefaults({
			fields: {},
			mode: "NONE",
		}),
	},
	_jobs: {},
	onboard: onboardReducerState,
	// INFO: by default it should be my-vessels, currently for testing purpose changed to all
	selectedTab: TAB_IDS.myVessels,
	favoriteVesselIds: [],
	fleets: [],
	// INFO: default value is all
	selectedFleetId: "all",
	enableSelection: false,
	selectedVesselsOnEnableSelection: [],
	selectedFleetIdToWhichVesselsToBeAssigned: "",
	fleetName: "",
	isCreateFleetModalOpen: false,
	isFleetListDropdownOpen: false,
	isAssignVesselsToFleetDropdownOpen: false,
	operationsPriorToDebounce: [],
};
const vesselReducers: Reducer<IVesselStore, any> = (
	state = reducerState,
	action
): IVesselStore => {
	switch (action.type) {
		case VESSEL_LIST_REMOVE: {
			delete state.vessels.byIds[
				(action as IVessel_ListRemove__Action).payload.vesselId
			];
			return state;
		}
		case VESSEL_LIST_SET: {
			return { ...state, vessels: (action as IVessel_ListSet__Action).payload };
		}
		case MY_VESSELS_LIST_SET: {
			return {
				...state,
				myVessels: (action as IVessel_ListSet__Action).payload,
			};
		}
		case VESSEL_LIST_PUSH: {
			const newVessel = (action as IVessel_ListPush__Action).payload;
			return {
				...state,
				vessels: {
					byIds: {
						...state.vessels.byIds,
						[newVessel.id]: newVessel,
					},
					ids: [...state.vessels.ids, newVessel.id],
				},
			};
		}
		case VESSEL_LIST_EDIT:
			const { vessel, vesselId } = (action as IVessel_listEdit__Action).payload;
			return {
				...state,
				vessels: {
					...state.vessels,
					byIds: {
						...state.vessels.byIds,
						[vesselId]: vessel,
					},
				},
			};
		case VESSEL_LIST_RESET:
			return { ...state, vessels: reducerState.vessels };
		case TAB_SELECT:
			const { tabSelected } = (action as ITab_Select).payload;
			return { ...state, selectedTab: tabSelected };
		case FAVORITED_VESSELS_SET:
			const { vesselIds } = (action as IVessel_FavoriteSet__Action).payload;
			return {
				...state,
				favoriteVesselIds: vesselIds,
			};
		case FLEET_LIST_SET:
			const { fleets } = (action as IFleet_ListSet__Action).payload;
			return {
				...state,
				fleets: [...fleets],
			};
		case FLEET_ID_SET:
			const { fleetId } = (action as IFleet_IdSet__Action).payload;
			return {
				...state,
				selectedFleetId: fleetId,
			};
		case VESSEL_SELECTION_ENABLE:
			const { isSelected } = (action as IEnable_VesselSelection__Action)
				.payload;
			return {
				...state,
				enableSelection: isSelected,
			};
		case VESSEL_SELECTED_ADD: {
			const { vesselId } = (action as ISelectedVessel_Add__Action).payload;
			const selectedVessels = state.selectedVesselsOnEnableSelection;
			let updatedSelectedVessels = [...selectedVessels];
			if (selectedVessels.includes(vesselId)) {
				updatedSelectedVessels = updatedSelectedVessels.filter(
					(id) => id !== vesselId
				);
			} else {
				updatedSelectedVessels = [...updatedSelectedVessels, vesselId];
			}
			return {
				...state,
				selectedVesselsOnEnableSelection: updatedSelectedVessels,
			};
		}
		case VESSEL_SELECTED_RESET: {
			return {
				...state,
				selectedVesselsOnEnableSelection: [],
			};
		}
		case ASSIGN_VESSELS_FLEET_ID_SET: {
			const { fleetId } = (action as IFleet_IdSet__Action).payload;
			return {
				...state,
				selectedFleetIdToWhichVesselsToBeAssigned: fleetId,
			};
		}
		case FLEET_CREATE_MODAL_TOGGLE: {
			return {
				...state,
				isCreateFleetModalOpen: !state.isCreateFleetModalOpen,
			};
		}
		case FLEET_NAME_ADD: {
			const { fleetName } = (action as IFleetName_Add__Action).payload;
			return {
				...state,
				fleetName,
			};
		}
		case FLEET_LIST_DROPDOWN_TOGGLE: {
			const { isOpen } = (action as IFleetDropdown_Toggle__Action).payload;
			return {
				...state,
				isFleetListDropdownOpen: isOpen,
			};
		}
		case ASSIGN_VESSEL_TO_FLEET_DROPDOWN_TOGGLE: {
			const { isOpen } = (action as IFleetDropdown_Toggle__Action).payload;
			return {
				...state,
				isAssignVesselsToFleetDropdownOpen: isOpen,
			};
		}
		case VESSEL_CARD_OPERATION_ADD: {
			const { operation } = (action as IVesselCard_OperationAdd__Action)
				.payload;
			return {
				...state,
				operationsPriorToDebounce: [
					...state.operationsPriorToDebounce,
					operation,
				],
			};
		}
		case VESSEL_CARD_OPERATIONS_RESET: {
			return {
				...state,
				operationsPriorToDebounce: [],
			};
		}
	}
	return {
		...state,
		onboard: vesselOnboardReducers(state.onboard, action),
	};
};

export default withForms(
	"@vessel",
	reducerState,
	withJobs("@vessel", reducerState, vesselReducers)
);
