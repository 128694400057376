import {
	IVesselOnboardStore,
	IVesselOnboard__Get_vesselForm,
	IVesselOnboard__Get_vesselEdit,
	IVesselOnboard__Get_crewList,
	IVesselOnboard__Get_deviceList,
	IVesselOnboard__Get_crewForm,
	IVesselOnboard__Get_deviceForm,
	IVesselOnboard__Get_crewListImport,
} from "./types";
import { jobSelectors } from "../../../_core/job";
import { formSelectors } from "../../../_core/form";
import actionTypes from "./action-types";
import { IListObjectStore } from "../../../_common/type";
import { IVesselCrew, IVesselDevice } from "../types";
import { IconName } from "@blueprintjs/core";
import { IStore } from "../../../store";
import { TMARPOLTank } from "redux/app/marpol/@types";

const {
	WIZARD_VESSEL_CREATE,
	WIZARD_VESSEL_UPDATE,
	WIZARD_VESSEL_IMAGE_UPLOAD,
	WIZARD_VESSEL_LOAD,
	WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD,
	WIZARD_CREW_LIST_LOAD,
	WIZARD_CREW_DELETE,
	WIZARD_DEVICE_LIST_LOAD,
	WIZARD_DEVICE_DELETE,

	TANK_LIST_LOAD,

	WIZARD_CREW_CREATE,
	WIZARD_CREW_IMAGE_UPLOAD,
	WIZARD_CREW_LOAD,
	WIZARD_CREW_UPDATE,
	WIZARD_CREW_LIST_IMPORT,
	WIZARD_CREW_DESIGNATIONS_LOAD,

	WIZARD_DEVICE_CREATE,
	WIZARD_DEVICE_LOAD,
	WIZARD_DEVICE_UPDATE,
	WIZARD_VESSEL_RANGES_LOAD,
	WIZARD_VESSEL_CONFIG_LIST_LOAD,
	WIZARD_VESSEL_CONFIG_LOAD,
	GET_TIME_LOGS
} = actionTypes.command;

export default {
	_getStore(store: IStore) {
		return store.app.vesselStore.onboard;
	},
	jobToLoadVessel(onboardStore: IVesselOnboardStore) {
		return jobSelectors.getJobStatus(onboardStore, WIZARD_VESSEL_LOAD);
	},
	// REFACTORME: Shift the Vessel form from onboard (aka onboard) to the root vessel store. becs the vessel form is now not being used in onboard it has its separate place now,
	getVesselFormData(
		onboardStore: IVesselOnboardStore
	): IVesselOnboard__Get_vesselForm {
		return {
			form: formSelectors(onboardStore._forms.VESSEL),
			jobs: {
				vesselCreate: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_VESSEL_CREATE
				),
				vesselUpdate: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_VESSEL_UPDATE
				),
				vesselImageUpload: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_VESSEL_IMAGE_UPLOAD
				),
				vesselLoad: jobSelectors.getJobStatus(onboardStore, WIZARD_VESSEL_LOAD),
			},
		};
	},
	getVesselEditData(
		onboardStore: IVesselOnboardStore
	): IVesselOnboard__Get_vesselEdit {
		return {
			vesselName: onboardStore._forms.VESSEL.fields.vesselName,
			jobs: {
				vesselLoad: jobSelectors.getJobStatus(onboardStore, WIZARD_VESSEL_LOAD),
				vesselUpdate: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_VESSEL_UPDATE
				),
			},
		};
	},
	getJobToLoadUsageAndAlertsData(onboardStore: IVesselOnboardStore) {
		return jobSelectors.getJobStatus(
			onboardStore,
			WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD
		);
	},
	getTimeLogs(onboardStore: IVesselOnboardStore) {
		return onboardStore.timeLogs;
	},
	jobToLoadTimeLogs(onboardStore: IVesselOnboardStore) {
		return jobSelectors.getJobStatus(
			onboardStore,
			GET_TIME_LOGS
		);
	},
	getCrewFormData(
		onboardStore: IVesselOnboardStore
	): IVesselOnboard__Get_crewForm {
		return {
			form: formSelectors(onboardStore._forms.CREW),
			crewDesignations: onboardStore.crewDesignations,
			jobs: {
				crewCreate: jobSelectors.getJobStatus(onboardStore, WIZARD_CREW_CREATE),
				crewImageUpload: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_CREW_IMAGE_UPLOAD
				),
				crewLoad: jobSelectors.getJobStatus(onboardStore, WIZARD_CREW_LOAD),
				crewUpdate: jobSelectors.getJobStatus(onboardStore, WIZARD_CREW_UPDATE),
				crewDesignationLoad: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_CREW_DESIGNATIONS_LOAD
				),
			},
		};
	},
	getDeviceFormData(
		onboardStore: IVesselOnboardStore
	): IVesselOnboard__Get_deviceForm {
		return {
			form: formSelectors(onboardStore._forms.DEVICE),
			jobs: {
				deviceCreate: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_DEVICE_CREATE
				),
				deviceLoad: jobSelectors.getJobStatus(onboardStore, WIZARD_DEVICE_LOAD),
				deviceUpdate: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_DEVICE_UPDATE
				),
			},
		};
	},
	getCrewListData(
		onboardStore: IVesselOnboardStore
	): IVesselOnboard__Get_crewList {
		function getOnboardStatusMessage(crew: IListObjectStore<IVesselCrew>) {
			// TODO: Add logic here
			return {
				totalCrew: 0,
				onboardedCrew: 0,
			};
		}
		return {
			jobs: {
				deleteCrewJob: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_CREW_DELETE
				),
				listLoadJob: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_CREW_LIST_LOAD
				),
			},
			crews: onboardStore.crews,
			onboardStatus: getOnboardStatusMessage(onboardStore.crews),
		};
	},
	getDevicesListData(
		onboardStore: IVesselOnboardStore
	): IVesselOnboard__Get_deviceList {
		function getOnboardStatusMessage(devices: IListObjectStore<IVesselDevice>) {
			return {
				totalDevices: 0,
				onboardedDevices: 0,
			};
		}
		return {
			jobs: {
				deleteDeviceJob: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_DEVICE_DELETE
				),
				listLoadJob: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_DEVICE_LIST_LOAD
				),
			},
			devices: onboardStore.devices,
			onboardStatus: getOnboardStatusMessage(onboardStore.devices),
		};
	},
	getTanksListData(onboardStore: IVesselOnboardStore) {
		function getOnboardStatusMessage(tanks: IListObjectStore<TMARPOLTank>) {
			return {
				totalTanks: 0,
				onboardedTanks: 0,
			};
		}
		return {
			jobs: {
				listLoadJob: jobSelectors.getJobStatus(onboardStore, TANK_LIST_LOAD),
			},
			tanks: onboardStore.tanks,
			onboardStatus: {
				totalTanks: onboardStore.tanks.length - 1,
			},
		};
	},
	getRangesData(onboardStore: IVesselOnboardStore) {
		return {
			jobs: {
				localRangesLoadJob: jobSelectors.getJobStatus(onboardStore, WIZARD_VESSEL_RANGES_LOAD),
				configListLoadJob: jobSelectors.getJobStatus(onboardStore, WIZARD_VESSEL_CONFIG_LIST_LOAD),
				configLoadJob: jobSelectors.getJobStatus(onboardStore, WIZARD_VESSEL_CONFIG_LOAD),
			},
			vesselId: onboardStore.vesselId,
			ranges: onboardStore.ranges,
			configLogsList: onboardStore.configLogsList,
			configInfo: onboardStore.configInfo
		};
	},
	getCrewImportData(
		onboardStore: IVesselOnboardStore
	): IVesselOnboard__Get_crewListImport {
		return {
			jobs: {
				import: jobSelectors.getJobStatus(
					onboardStore,
					WIZARD_CREW_LIST_IMPORT
				),
			},
			importErrors: {},
		};
	},
	getCrewFormStaticData() {
		return {
			name: {
				label: "Name",
				placeholder: "",
			},
			fromDate: {
				label: "From date",
				placeholder: "",
			},
			toDate: {
				label: "To date",
				placeholder: "",
			},
			designation: {
				label: "Designation",
				placeholder: "",
			},
			staffId: {
				label: "Staff id",
				placeholder: "",
			},
			image: {
				label: "Image",
				placeholder: "Image",
			},
			email: {
				label: "Email",
				placeholder: "",
			},
		};
	},
	getDeviceFormStaticData() {
		return {
			deviceName: {
				label: "Device Name",
			},
			deviceType: {
				label: "Device Type",
				placeholder: "",
				options: [
					{
						label: "Tablet",
						icon: "mobile-phone" as IconName,
						value: "TABLET",
					},
					{
						label: "Desktop",
						icon: "desktop" as IconName,
						value: "DESKTOP",
					},
				],
			},
			// operatingSystem: {
			// 	label: "Operating system",
			// 	placeholder: "",
			// 	options: ["Windows", "Android", "IOS"],
			// },
			// version: {
			// 	label: "Version",
			// 	placeholder: "",
			// 	options: ["1.0.35", "1.0.36"],
			// },
			macAddress: {
				label: "Mac address",
				placeholder: "",
			},
			deviceId: {
				label: "Device id",
				placeholder: "",
			},
		};
	},
};
