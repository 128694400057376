const VESSEL_LIST_REMOVE = "@vessel/list/REMOVE"; // COMMAND ACTION
const VESSEL_LIST_LOAD = "@vessel/list/LOAD"; // COMMAND ACTION
const VESSEL_LIST_LOAD_NEW = "@vessel/list/new/LOAD"; // COMMAND ACTION
const VESSEL_LIST_FILTER = "@vessel/list/FILTER"; // COMMAND ACTION
const FAVORITE_A_VESSEL = "@vessel/user/favorite"; // COMMAND ACTION
const FLEET_LIST_LOAD = "@vessel/fleet/list/LOAD"; // COMMAND ACTION
const FLEET_VESSEL_LIST_LOAD = "@vessel/fleet-vessels/list/LOAD"; // COMMAND ACTION
const FLEET_SELECTED_VESSEL_ASSIGN = "@vessel/fleet/selected-vessels/ASSIGN"; // COMMAND ACTION
const FLEET_CREATE = "@vessel/fleet/CREATE"; // COMMAND ACTION

const VESSEL_LIST_SET = "@vessel/list/SET"; // DOCUMENT ACTION
const VESSEL_LIST_RESET = "@vessel/list/RESET"; // DOCUMENT ACTION
const VESSEL_LIST_PUSH = "@vessel/list/PUSH"; // DOCUMENT ACTION
const VESSEL_LIST_EDIT = "@vessel/list/EDIT"; // DOCUMENT ACTION
const TAB_SELECT = "@vessel/tab/SELECT"; // DOCUMENT ACTION
const MY_VESSELS_LOAD = "@vessel/user/vessels/LOAD"; // DOCUMENT ACTION
const FAVORITED_VESSELS_SET = "@vessel/user/favorited/SET"; // DOCUMENT ACTION
const MY_VESSELS_LIST_SET = "@vessel/list/my-vessels/SET"; // DOCUMENT ACTION
const FLEET_LIST_SET = "@vessel/fleet/list/SET"; // DOCUMENT ACTION
const FLEET_ID_SET = "@vessel/fleet/select/SET"; // DOCUMENT ACTION
const VESSEL_SELECTION_ENABLE = "@vessel/vessel-selection/ENABLE"; // DOCUMENT ACTION
const VESSEL_SELECTED_ADD = "@vessel/vessel/selected/ADD"; // DOCUMENT ACTION
const VESSEL_SELECTED_RESET = "@vessel/vessel/selected/RESET"; // DOCUMENT ACTION
const ASSIGN_VESSELS_FLEET_ID_SET = "@vessel/fleet/assign-vessels/fleet-id/SET"; // DOCUMENT ACTION
const FLEET_NAME_ADD = "@vessel/fleet-name/ADD"; // DOCUMENT ACTION
const FLEET_CREATE_MODAL_TOGGLE = "@vessel/fleet/create/modal/TOGGLE"; // DOCUMENT ACTION
const FLEET_LIST_DROPDOWN_TOGGLE = "@vessel/fleet/list/dropdown/TOGGLE"; // DOCUMENT ACTION
const ASSIGN_VESSEL_TO_FLEET_DROPDOWN_TOGGLE =
	"@vessel/assign-vessel-to-fleet/dropdown/TOGGLE"; // DOCUMENT ACTION
const VESSEL_CARD_OPERATION_ADD = "@vessel/vessel-card/operation/ADD"; //DOCUMENT ACTION
const VESSEL_CARD_OPERATIONS_RESET = "@vessel/vessel-card/operations/RESET"; // DOCUMENT ACTION

const vesselActionTypes = {
	VESSEL_LIST_REMOVE,
	VESSEL_LIST_LOAD,
	VESSEL_LIST_LOAD_NEW,
	VESSEL_LIST_FILTER,

	VESSEL_LIST_SET,
	VESSEL_LIST_RESET,
	VESSEL_LIST_PUSH,
	VESSEL_LIST_EDIT,

	TAB_SELECT,
	MY_VESSELS_LOAD,
	FAVORITED_VESSELS_SET,
	FAVORITE_A_VESSEL,
	MY_VESSELS_LIST_SET,
	FLEET_LIST_LOAD,
	FLEET_LIST_SET,
	FLEET_VESSEL_LIST_LOAD,
	FLEET_ID_SET,
	VESSEL_SELECTION_ENABLE,
	VESSEL_SELECTED_ADD,
	VESSEL_SELECTED_RESET,
	FLEET_SELECTED_VESSEL_ASSIGN,
	ASSIGN_VESSELS_FLEET_ID_SET,
	FLEET_NAME_ADD,
	FLEET_CREATE_MODAL_TOGGLE,
	FLEET_CREATE,
	FLEET_LIST_DROPDOWN_TOGGLE,
	ASSIGN_VESSEL_TO_FLEET_DROPDOWN_TOGGLE,
	VESSEL_CARD_OPERATION_ADD,
	VESSEL_CARD_OPERATIONS_RESET,
};

export default vesselActionTypes;
