import React, { FC, useState } from 'react';
import { IData } from '.';
import Buttons from './Buttons';
import Range from './Range';
import Remarks from './Remarks';
import { ILocalRange } from 'redux/app/vessel/onboard/types';
import { ModalType } from '../Ranges';

interface IEditModalProps {
    data: IData;
    setData: React.Dispatch<React.SetStateAction<IData>>;
    onCancel: () => void;
    onSubmit: (localRange?: ILocalRange) => void;
}
const EditModal: FC<IEditModalProps> = ({ data,onCancel, onSubmit , setData }) => {
    const [rangeErrorMsg, setRangeErrorMsg] = useState('');
    const [remarksErrorMsg, setRemarksErrorMsg] = useState('');
    return (
        <>
            <Range 
                rangeErrorMsg={rangeErrorMsg}
                setRangeErrorMsg={setRangeErrorMsg}
                data={data}
                setData={setData}
            />
            <Remarks data={data} setData={setData} remarksErrorMsg={remarksErrorMsg} />
            <Buttons
                setRangeErrorMsg={setRangeErrorMsg}
                btn1={
                    {
                        isVisible: true,
                        text: 'Save',
                        className: 'text-secondary'
                    }
                }
                btn2={{
                    isVisible: true,
                    text: 'Cancel'
                }}
                modalType={ModalType.Edit}
                data={data}
                onCancel={onCancel}
                onSubmit={(localRange) => {
                    if (!data.remarks) {
                        setRemarksErrorMsg('Remarks are required.');
                        return;
                    }
                    onSubmit(localRange);
                }}
            />
        </>
    );
};

export default EditModal;
