import React, { FC } from 'react';
import { getEmptyValueLabelObj, IData, TValueLabelObj } from '.';
import WayshipDropdownDeck from './WayshipDropdownDeck';

interface ICategoryProps {
    data: IData;
    logCategories: any;
    setData: React.Dispatch<React.SetStateAction<IData>>;
}

export const findSelectedObj = (range: TValueLabelObj[], selectedValue: string) => {
    return range?.find((item: any) => (item?.value === selectedValue));
}

const Category: FC<ICategoryProps> = ({ data, logCategories, setData }) => {
    const range = logCategories && logCategories?.length > 0
    ? logCategories?.map((category: any) => ({
          label: category?.title || category?.mnemonic,
          value: category?.mnemonic || category?.title,
      }))
    : [{ label: '', value: '' }];
    return (
        <div>
            <WayshipDropdownDeck
                title="Select Category"
                dataKey=""
                value={data.category.value}
                range={range}
                onChangeHandler={(selectedItem: any, dataKey: any) => {
                    const obj = findSelectedObj(range, selectedItem);
                    setData((prev) => ({
                        category: {
                            label: obj?.label,
                            value: selectedItem
                        },
                        section: getEmptyValueLabelObj(),
                        modules: getEmptyValueLabelObj(),
                        subModules: getEmptyValueLabelObj(),
                        metric: getEmptyValueLabelObj(),
                        range: [undefined, undefined],
                        key: '',
                        remarks: '',
                    }));
                }}
            />
        </div>
    );
};

export default Category;
