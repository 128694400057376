import React from 'react';
import InputWrapper, { InputWrapperProps } from './InputWrapper';
import Downshift, {
    DownshiftState,
    GetInputPropsOptions,
    GetToggleButtonPropsOptions,
    StateChangeOptions,
} from 'downshift';
import classNames from 'classnames';
import { IconName } from '@blueprintjs/core';

type IDropdownOption<T> = {
    value: string;
    label: string;
    icon?: IconName;
    rightIcon?: IconName;
} & T;
type DropdownProps<T> = React.PropsWithChildren<{
    /**
     * Unique id required to reference and differentiate between multiple dropdown..
     * Its also required to remember dropdown on any rerender
     */
    id: string;
    inputWrapperProps: InputWrapperProps;
    options: IDropdownOption<T>[];
    emptyOption?: IDropdownOption<T>;
    disabled?: boolean;
    placeholder?: string;
    value?: string;
    /**
     * Dont filter as per input text @default false
     */
    noFilter?: boolean;
    isReadOnly?: boolean;
    renderListItem?(listItem: IDropdownOption<T>): React.ReactElement;
    renderView?(selectedItem: IDropdownOption<T>): React.ReactElement;
    renderEmptyView?(): React.ReactElement;
    onSelect(value: IDropdownOption<T> | null): void;
    onFocus?: () => void;
	style?: React.CSSProperties
}>;
function Dropdown<T>(props: DropdownProps<T>) {
    const {
        disabled,
        inputWrapperProps,
        options,
        emptyOption,
        value,
        placeholder,
        noFilter = true,
        isReadOnly = false,
        id,
        onFocus,
		style = {}
    } = props;
    const dropdownViewId = 'dropdown-view-' + id;
    const { className: inputWrapperClassName, ...restInputWrapperProps } =
        inputWrapperProps;

    const optionsObject = options.reduce<{ [key: string]: IDropdownOption<T> }>(
        (result, item, index) => {
            result[item.value] = item;
            return result;
        },
        emptyOption ? { [emptyOption.value]: emptyOption } : {}
    );

    function itemToString(item: IDropdownOption<T> | null) {
        return item ? item.label : '';
    }
    function onSelect(selection: IDropdownOption<T> | null) {
        props.onSelect(selection);
    }
    function reduceState(
        state: DownshiftState<IDropdownOption<T>>,
        changes: StateChangeOptions<IDropdownOption<T>>
    ): Partial<StateChangeOptions<IDropdownOption<T>>> {
        return changes;
    }
    return (
        <Downshift
            itemToString={itemToString}
            onChange={onSelect}
            initialSelectedItem={optionsObject[value || '']}
            stateReducer={reduceState}
            selectedItem={optionsObject[value || '']}
        >
            {({
                getRootProps,
                getInputProps,
                getToggleButtonProps,
                getItemProps,
                getMenuProps,
                isOpen,
                highlightedIndex,
                selectedItem,
                inputValue,
            }) => (
                <div
                    className="p-0 border-0 ws-input"
                    {...getRootProps(undefined, { suppressRefError: true })}
					style={{...style}}
                >
                    <InputWrapper
                        className={classNames(
                            'relative flex-row justify-start w-full p-0 m-0 ',
                            inputWrapperClassName
                        )}
                        {...restInputWrapperProps}
                    >
                        <button
                            onFocus={onFocus}
                            {...getToggleButtonProps({
                                id: dropdownViewId,
                                disabled,
                                className: classNames(
                                    'w-full my-0 ws-input text-left flex flex-row justify-between items-center cursor-pointer outline-none shadow-none',
                                    {
                                        'ws-input--read-only': isReadOnly,
                                    }
                                ),
                            })}
                        >
                            {selectedItem ? (
                                props.renderView ? (
                                    props.renderView(selectedItem)
                                ) : (
                                    <span className="whitespace-nowrap overflow-hidden">
                                        {selectedItem.label}&nbsp;
                                    </span>
                                )
                            ) : props.renderEmptyView ? (
                                props.renderEmptyView()
                            ) : (
                                <span className="block text-gray-500">
                                    {placeholder ? (
                                        placeholder
                                    ) : (
                                        <span>&nbsp;</span>
                                    )}
                                </span>
                            )}
                            {!isReadOnly ? (
                                <div className="flex-col items-center justify-center px-1 my-0 border-l-0 rounded-b-none rounded-l-none pointer-events-none hover:bg-gray-200 focus:bg-gray-300 justify-items-center">
                                    <span className="mb-px text-gray-600 text-md bp3-icon bp3-icon-caret-down" />
                                </div>
                            ) : null}
                        </button>
                    </InputWrapper>
                    <div className="relative h-0">
                        {isOpen ? (
                            <ul
                                className="absolute top-0 z-50 w-full p-0 m-0 overflow-y-auto bg-white border-t-0 border-gray-200 rounded-t-none shadow-lg ws-input max-h-64 ws-scroll rounded-b-md"
                                {...getMenuProps({})}
                            >
                                {options
                                    .filter((option) =>
                                        noFilter
                                            ? true
                                            : inputValue
                                            ? option.label.includes(
                                                  inputValue
                                              ) ||
                                              option.value.includes(inputValue)
                                            : true
                                    )
                                    .sort()
                                    .map((option, index) => {
                                        return (
                                            <li
                                                key={index}
                                                {...getItemProps({
                                                    item: option,
                                                })}
                                            >
                                                <div
                                                    className={classNames(
                                                        'm-0 ws-input bg-white border-transparent hover:bg-gray-200 flex flex-row justify-start items-center cursor-pointer w-full focus:bg-gray-300 hover:border-gray-400 rounded-none',
                                                        {
                                                            'border-gray-700 bg-gray-300':
                                                                highlightedIndex ===
                                                                index,
                                                            'border-blue-700 bg-blue-200 border-2':
                                                                selectedItem?.value ===
                                                                option.value,
                                                        }
                                                    )}
                                                >
                                                    {props.renderListItem ? (
                                                        props.renderListItem(
                                                            option
                                                        )
                                                    ) : (
                                                        <span className="">
                                                            {option.label}&nbsp;
                                                        </span>
                                                    )}
                                                </div>
                                            </li>
                                        );
                                    })}
                            </ul>
                        ) : null}
                    </div>
                </div>
            )}
        </Downshift>
    );
}

export default Dropdown;
