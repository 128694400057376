import { IAction, IListObjectStore } from "../../../_common/type";
import { IVesselCrew, IVesselDevice, IVessel } from "../types";
import { IJobHOCState, IJobGetStatus } from "../../../_core/job/types";
import { IFormStore, IForm, IForm__Get } from "../../../_core/form/types";
import { Action } from "redux";
import { TMARPOLTank } from "redux/app/marpol/@types";
import { ActionType } from "typesafe-actions";
import vesselOnboardActions from "./actions";
import { IConfigInfo, ILogListItem } from "redux/app/tool/@types";

// All Actions below
export type IVesselOnboard_DashboardUsageAndAlertsSet__Action = IAction<{
	usabilityAndAlerts: IDashboardUsageAndAlerts;
}>;

export type IVesseOnboard_vesselCreate__Action = IAction<{ vessel: IVessel }>;
export type IVesseOnboard_vesselConfigListLoad__Action = IAction<{}>;
export type IVesseOnboard_vesselConfigLoad__Action = IAction<{ configId?: string }>;
export type IVesseOnboard_vesselRangesDelete__Action = IAction<{ ranges: Ranges }>;
export type IVesseOnboard_vesselRangesEdit__Action = IAction<{ ranges: Ranges }>;
export type IVesseOnboard_vesselRangesAdd__Action = IAction<{ ranges: Ranges }>;
export type IVesseOnboard_vesselUpdate__Action = IAction<{
	vessel: Partial<IVessel>;
	vesselId: string;
}>;
export type IVesselOnboard_vesselLoad__Action = IAction<{
	vesselId: string;
	notify: boolean;
}>;
export type IVesselOnboard_vesselImageUpload__Action = IAction<{
	file: File;
}>;
export type IVesselOnboard_DashboardUsabilityAlertsLoad__Action = IAction<{
	vesselId: string;
	fromDate?: string;
	toDate?: string;
}>;

export type IVesselOnboard_crewListLoad__Action = IAction<{
	vesselId: string;
}>;
export type IVesselOnboard_crewListImport__Action = IAction<{
	file: File;
	vesselId: string;
}>;
export type IVesselOnboard_deviceListLoad__Action = IAction<{
	vesselId: string;
}>;
export type IVesselOnboard_deviceListImport__Action = IAction<{
	file: File;
	vesselId: string;
}>;
export type IVesselOnboard_crewLoad__Action = IAction<{ crewId: string }>;
export type IVesselOnboard_crewDesignationLoad__Action = IAction<{}>;
export type IVesselOnboard_crewImageUpload__Action = IAction<{
	file: File;
}>;
export type IVesselOnboard_crewCreate__Action = IAction<{
	crew: IVesselCrew;
}>;
export type IVesselOnboard_crewUpdate__Action = IAction<{
	crewId: string;
	crew: Partial<IVesselCrew>;
}>;
export type IVesselOnboard_crewDelete__Action = IAction<{
	crewId: string;
}>;
export type IVesselOnboard_deviceLoad__Action = IAction<{
	deviceId: string;
}>;
export type IVesselOnboard_deviceCreate__Action = IAction<{
	device: IVesselDevice;
}>;
export type IVesselOnboard_deviceImageUpload__Action = IAction<{
	file: File;
	device: IVesselDevice;
}>;
export type IVesselOnboard_deviceUpdate__Action = IAction<{
	deviceId: string;
	device: Partial<IVesselDevice>;
}>;
export type IVesselOnboard_deviceDelete__Action = IAction<{
	deviceId: string;
}>;

export type IVesselOnboard_rangesSet__Action = IAction<{
	vesselRanges: Ranges;
}>;
export type IVesselOnboard_configLogsListSet__Action = IAction<{
	logs: IListObjectStore<ILogListItem>;
}>;
export type IVesselOnboard_configInfoSet__Action = IAction<{
	configInfo: IConfigInfo;
}>;
export type IVesselOnboard_timeLogsSet__Action = IAction<
	{
		timeLogs: ITimeLog[];
	}
>;
export type IVesselOnboard_timeLogsAppend__Action = IAction<
	{
		timeLogs: ITimeLog[];
	}
>;
export type IVesselOnboard_crewListSet__Action = IAction<
	IListObjectStore<IVesselCrew>
>;
export type IVesselOnboard_deviceListSet__Action = IAction<
	IListObjectStore<IVesselDevice>
>;
export type IVesselOnboard_crewNew__Action = IAction<{
	crew: IVesselCrew;
}>;
export type IVesselOnboard_crewSet__Action = IAction<{
	crew: IVesselCrew;
}>;
export type IVesselOnboard_crewDesignationSet__Action = IAction<{
	designations: string[];
}>;

export type IVesselOnboard_crewEdit__Action = IAction<{
	crew: Partial<IVesselCrew> & Required<{ id: string }>;
}>;
export type IVesselOnboard_crewErase__Action = IAction<{
	crewId: string;
}>;
export type IVesselOnboard_deviceNew__Action = IAction<{
	device: IVesselDevice;
}>;
export type IVesselOnboard_deviceSet__Action = IAction<{
	device: IVesselDevice;
}>;
export type IVesselOnboard_deviceEdit__Action = IAction<{
	device: Partial<IVesselDevice> & Required<{ id: string }>;
}>;
export type IVesselOnboard_deviceErase__Action = IAction<{
	deviceId: string;
}>;
export type IVesselOnboard_reset__Action = Action<string>;

// All Forms below
export interface IVesselOnboardForm extends IForm<IVessel> {}
export interface IVesselOnboardFormCrew extends IForm<IVesselCrew> {}
export interface IVesselOnboardFormDevice extends IForm<IVesselDevice> {}

export enum EDashboardUsabilityStatus {
	COMPLETE = "COMPLETE",
	PARTIAL = "PARTIAL",
	NOT_DONE = "NOT_DONE",
}

export enum EDashboardAlertsStatus {
	ALL_GOOD = "ALL_GOOD",
	MEDIUM = "MEDIUM",
	HIGH = "HIGH",
}
export interface IDashboardUsabilityEngine {
	watchHour: number[];
	isApprove: boolean;
	state: EDashboardUsabilityStatus;
}

export interface IDashboardUsabilityORB1 {
	status: string[]; //TODO: status can be APPROVED-not yet clear so string[]
	state: EDashboardUsabilityStatus;
}

export interface IDashboardUsabilityGarbage {
	status: string[]; //TODO: status can be APPROVED-not yet clear so string[]
	state: EDashboardUsabilityStatus;
}

export interface IDashboardUsability {
	engine: {
		[dateStr: string]: IDashboardUsabilityEngine;
	};
	deck: {
		[dateStr: string]: IDashboardUsabilityEngine;
	};
	orb1: {
		[dateStr: string]: IDashboardUsabilityORB1;
	};
	annex6: any;
	ballast: any;
	orb2: any;
	garbage: {
		[dateStr: string]: IDashboardUsabilityGarbage;
	};
}

export interface IDashboardAlertsEngine {
	[dateStr: string]: number;
}

export interface IDashboardAlerts {
	engine: IDashboardAlertsEngine;
}

export interface IDashboardUsageAndAlerts {
	vesselId: string;
	fromDate: string;
	toDate: string;
	usability: IDashboardUsability;
	alerts: IDashboardAlerts;
}

// Vessel Onboard Store
export interface IVesselOnboardStore extends IFormStore, IJobHOCState {
	timeLogs: ITimeLog[];
	vesselId: string | null;
	dashboardUsageAndAlerts: IDashboardUsageAndAlerts | null;
	crews: IListObjectStore<IVesselCrew>;
	devices: IListObjectStore<IVesselDevice>;
	tanks: TMARPOLTank[];
	/** This is fetched from server because designation can be dynamic for different vessels/tenants */
	crewDesignations: string[];
	ranges: Ranges;
	configLogsList: IListObjectStore<ILogListItem>;
	configInfo: IConfigInfo | null;
	_forms: {
		VESSEL: IVesselOnboardForm;
		CREW: IVesselOnboardFormCrew;
		DEVICE: IVesselOnboardFormDevice;
	};
}

export type IVesselOnboard__Get_vesselForm = {
	form: IForm__Get<IVessel>;
	jobs: {
		vesselCreate: IJobGetStatus;
		vesselUpdate: IJobGetStatus;
		vesselImageUpload: IJobGetStatus;
		vesselLoad: IJobGetStatus;
	};
};
export type IVesselOnboard__Get_vesselEdit = {
	vesselName: string;
	jobs: {
		vesselUpdate: IJobGetStatus;
		vesselLoad: IJobGetStatus;
	};
};
export type IVesselOnboard__Get_crewList = {
	jobs: {
		listLoadJob: IJobGetStatus;
		deleteCrewJob: IJobGetStatus;
	};
	crews: IListObjectStore<IVesselCrew>;
	onboardStatus: {
		totalCrew: number;
		onboardedCrew: number;
	};
};
export type IVesselOnboard__Get_deviceList = {
	jobs: {
		listLoadJob: IJobGetStatus;
		deleteDeviceJob: IJobGetStatus;
	};
	devices: IListObjectStore<IVesselDevice>;
	onboardStatus: {
		totalDevices: number;
		onboardedDevices: number;
	};
};
export type IVesselOnboard__Get_crewForm = {
	form: IForm__Get<IVesselCrew>;
	crewDesignations: string[];
	jobs: {
		crewCreate: IJobGetStatus;
		crewUpdate: IJobGetStatus;
		crewImageUpload: IJobGetStatus;
		crewLoad: IJobGetStatus;
		crewDesignationLoad: IJobGetStatus;
	};
};
export type IVesselOnboard__Get_deviceForm = {
	form: IForm__Get<IVesselDevice>;
	jobs: {
		deviceCreate: IJobGetStatus;
		deviceUpdate: IJobGetStatus;
		deviceLoad: IJobGetStatus;
	};
};

export type IVesselOnboard__Get_crewListImport = {
	jobs: {
		import: IJobGetStatus;
	};
	importErrors: any;
};

export interface IVesselDateRange { 
	startDate: Date; 
	endDate: Date; 
	key: string 
}

export interface Ranges {
	[key: string]: ILocalRange;
}

export interface ILocalRange {
	key: string;
	range: (number | undefined)[];
	isLocalRange?: boolean;
	metric: string;
	modifiedAt?: string;
	modifiedBy?: {
		id: string;
		name: string;
	};
	remarks: string;
};

export type TRangeManagement = {
    config: any;
};

export interface ITimeLog {
	_id: string;
  	_rev: string;
  	modelName: string;
  	vesselName: string;
  	cloudServerTime: string;
  	localServerTime: string;
  	createdAt: string;
	timeDifference: number;
	difference: string;
}